import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, useMediaQuery } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import useStyles from './styles';

/**
 * This component genereta a legend based in a bar.
 * @param {A list containing the selection options} options
 */
export default function BarLegend({
  colors,
  title,
  breaks,
  minValue,
  maxValue,
  indisponibleColor,
  indisponibleText,
}) {
  BarLegend.defaultProps = {
    indisponibleColor: undefined,
    indisponibleText: undefined,
  };

  BarLegend.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
    breaks: PropTypes.arrayOf(PropTypes.number).isRequired,
    minValue: PropTypes.string.isRequired,
    maxValue: PropTypes.string.isRequired,
    indisponibleColor: PropTypes.string,
    indisponibleText: PropTypes.string,
  };
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [opened, setOpened] = useState(false);
  const mobileWidth = useMediaQuery('(max-width: 599px)');
  const [coloredDiv, setColoredDiv] = useState();

  /**
   * This function generates a colored div.
   */
  useEffect(() => {
    if (colors && breaks) {
      const biggest = breaks[breaks.length - 1];
      let gradient = 'linear-gradient(90deg, ';

      colors.reverse().forEach((color, index) => {
        gradient += `${color} ${(100 * breaks[index]) / biggest}%`;

        if (index + 1 < colors.length) {
          gradient += ', ';
        } else {
          gradient += ')';
        }
      });

      setColoredDiv(
        <div
          style={{
            width: '100%',
            height: 12,
            background: gradient,
            borderRadius: 50,
          }}
        />
      );
    }
  }, [colors, breaks]);

  /**
   * This function handle the legend opening
   */
  function handleOpen() {
    setOpened(!opened);
  }

  return (
    <div className={classes.container}>
      <Grow in={!opened}>
        <div
          className={classes.closedContainer}
          role="button"
          onClick={handleOpen}
          onKeyDown={handleOpen}
          tabIndex={-1}
        >
          <div
            style={{
              backgroundColor: colors[colors.length - 1],
              borderRadius: 10,
              width: 18,
              height: 18,
              position: 'relative',
            }}
          >
            <div
              style={{
                backgroundColor:
                  colors[colors.length - 1 - (colors.length / 4).toFixed()],
                borderRadius: 10,
                width: 15,
                height: 15,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            />
            <div
              style={{
                backgroundColor: colors[(colors.length / 4).toFixed() - 1],
                borderRadius: 10,
                width: 12,
                height: 12,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: colors[0],
                  borderRadius: 10,
                  width: 8,
                  height: 8,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                }}
              />
            </div>
          </div>
        </div>
      </Grow>
      <Grow
        style={{ transformOrigin: mobileWidth ? '100% 0 0' : '0 0 0' }}
        in={opened}
      >
        <div className={classes.openedContainer}>
          <div
            className={classes.closeButton}
            role="button"
            onClick={handleOpen}
            onKeyDown={handleOpen}
            tabIndex={-1}
          >
            <CloseIcon style={{ fontSize: 16 }} />
          </div>
          <Typography
            variant="body2"
            style={{
              margin: '0px 25px',
            }}
            align="center"
          >
            {title}
          </Typography>
          <div className={classes.colorsContainer}>{coloredDiv}</div>
          <div className={classes.valuesContainer}>
            <Typography variant="body2">{minValue}</Typography>
            <Typography variant="body2">{maxValue}</Typography>
          </div>
          {indisponibleColor && indisponibleText && (
            <>
              <div className={classes.divisor} />
              <div className={classes.indisponibleContainer}>
                <div
                  className={classes.ball}
                  style={{ backgroundColor: indisponibleColor }}
                />
                <Typography variant="body2" style={{ marginLeft: 10 }}>
                  {indisponibleText}
                </Typography>
              </div>
            </>
          )}
        </div>
      </Grow>
    </div>
  );
}
