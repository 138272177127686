import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

import en from './translations/en.json';
import es from './translations/es.json';
import pt from './translations/pt.json';

import dataMethodEn from './translations/methods/data/en.json';
import dataMethodEs from './translations/methods/data/es.json';
import dataMethodPt from './translations/methods/data/pt.json';

import projectionsMethodEn from './translations/methods/projections/en.json';
import projectionsMethodEs from './translations/methods/projections/es.json';
import projectionsMethodPt from './translations/methods/projections/pt.json';

import publicationsMethodEn from './translations/methods/publications/en.json';
import publicationsMethodEs from './translations/methods/publications/es.json';
import publicationsMethodPt from './translations/methods/publications/pt.json';

const options = {
  // Order and from where user language should be detected.
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path',
  ],

  // Keys or params to lookup language from:
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,

  // Cache user language on:
  caches: ['localStorage', 'cookie'],

  // Optional expire and domain for set cookie.
  cookieMinutes: 1,
  cookieDomain: 'myDomain',

  // Optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // Only detect languages that are in the whitelist.
  checkWhitelist: true,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
        dataMethod: dataMethodEn,
        projectionsMethod: projectionsMethodEn,
        publicationsMethod: publicationsMethodEn,
      },
      es: {
        translation: es,
        dataMethod: dataMethodEs,
        projectionsMethod: projectionsMethodEs,
        publicationsMethod: publicationsMethodEs,
      },
      pt: {
        translation: pt,
        dataMethod: dataMethodPt,
        projectionsMethod: projectionsMethodPt,
        publicationsMethod: publicationsMethodPt,
      },
    },
    fallbackLng: ['en', 'pt'],
    whitelist: ['en', 'es', 'pt'],
    detection: options,

    interpolation: {
      escapeValue: false,
      // Format items to each language format.
      format(value, format, lng) {
        if (format === 'number') return value.toLocaleString(lng);
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

export default i18n;
