import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Brightness4, Brightness7, Menu as MenuIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import ThemeContext from '../../context/theming';
import useStyles from './styles';
import platformLogo from '../../assets/images/logo.svg';
import platformLogo2 from '../../assets/images/logo2.svg';
import { lightTheme } from '../../constants/themes';
import HeaderButton from './header-button';
import TranslationsButton from './translations-button';
import MapsButton from './maps-button';
import HeaderDrawer from './header-drawer';
import { availableMaps } from '../../constants/general';
import { defaultPageMaxWidth } from '../../constants/sizes';

/**
 * The Header component creates a NavBar.
 */
function Header({ reduced, enableBackground }) {
  Header.defaultProps = {
    reduced: false,
    enableBackground: true,
  };

  Header.propTypes = {
    reduced: PropTypes.bool,
    enableBackground: PropTypes.bool,
  };

  const { selectedTheme, switchTheme } = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [mapPage, setMapPage] = useState(false);

  /**
   * This useEffect selects if the current page is a map page.
   */
  useEffect(() => {
    const availableMapsArray = Object.keys(availableMaps);
    const found = availableMapsArray.find(
      (map) => availableMaps[map].url === location.pathname
    );

    setMapPage(found);
  }, [location]);

  /**
   * This function handle logo click.
   */
  function handleLogoClick() {
    history.push('/');
  }

  return (
    <div
      className={classes.container}
      style={
        enableBackground
          ? {}
          : { backgroundColor: 'transparent', boxShadow: 'none' }
      }
    >
      <div
        className={classes.containerWrapper}
        style={{ maxWidth: reduced ? defaultPageMaxWidth : '100%' }}
      >
        <div
          role="button"
          className={classes.logoWrapper}
          onClick={handleLogoClick}
          onKeyDown={handleLogoClick}
          tabIndex={0}
        >
          <div className={classes.platformLogoWrapper}>
            <img
              className={classes.platformLogo}
              style={{ opacity: location.pathname === '/vaccination' ? 1 : 0 }}
              src={platformLogo2}
              alt={t('general_platformName')}
            />
            <img
              className={classes.platformLogo}
              style={{ opacity: location.pathname === '/vaccination' ? 0 : 1 }}
              src={platformLogo}
              alt={t('general_platformName')}
            />
          </div>

          <div className={classes.platformName}>
            {t('general_platformName')}
            <div className={classes.platformSubtitle}>
              {t('general_platformSubtitle')}
            </div>
          </div>
        </div>
        <div className={classes.desktopWrapper}>
          <div className={classes.leftButtonsWrapper}>
            <HeaderButton
              url="/"
              autoSelect={false}
              selected={false}
              translation="header_startPageButton"
            />
            <div className={classes.verticalSeparator} />
            <MapsButton />
            {/* <div className={classes.verticalSeparator} />
            <HeaderButton
              url="/reports"
              selected={location.pathname === '/reports'}
              translation="header_reportsPageButton"
            /> */}
          </div>
          <div className={classes.rightButtonsWrapper}>
            <TranslationsButton />
            <div className={classes.verticalSeparator} />
            <IconButton aria-label="theme" onClick={switchTheme}>
              {selectedTheme === lightTheme ? (
                <Brightness4 className={classes.iconButton} />
              ) : (
                <Brightness7 className={classes.iconButton} />
              )}
            </IconButton>
          </div>
        </div>
        <div className={classes.mobileWrapper}>
          {mapPage && (
            <div className={classes.mobileMapsButtonWrapper}>
              <MapsButton />
              <div className={classes.verticalSeparator} />
            </div>
          )}
          <IconButton
            aria-label="menu"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon className={classes.iconButton} />
          </IconButton>
        </div>
      </div>
      <HeaderDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        selectedColor={
          location.pathname === '/vaccination'
            ? theme.colorTertiary
            : theme.colorPrimary
        }
      />
    </div>
  );
}

export default Header;
