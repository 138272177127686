import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  iconButton: {
    color: theme.colorText,
  },
  portalButtonText: {
    color: theme.colorText,
    fontSize: '1rem',
    textTransform: 'none',
  },
  portalExtendedButton: {
    margin: '0px 5px',

    '@media (max-width: 979px)': {
      display: 'none',
    },
  },
  portalMinorButton: {
    display: 'none',

    '@media (max-width: 979px)': {
      display: 'block',
    },

    '@media (max-width: 499px)': {
      display: 'none',
    },
  },
  menuPopOver: {
    '& .MuiPaper-root': {
      backgroundColor: theme.colorTertiaryBackground,
      color: theme.colorText,
    },
  },
}));

export default useStyles;
