import { createUseStyles } from 'react-jss';
import { mapNavigationMarginTop } from '../../constants/sizes';

const useStyles = createUseStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '@media (max-width: 599px)': {
      '& .mapboxgl-ctrl-bottom-left': {
        bottom: 20,
      },
      '& .mapboxgl-ctrl-bottom-right': {
        bottom: 20,
      },
    },
  },
  navigationContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    left: 20,
    top: 20,
    pointerEvents: 'none',

    '@media (max-width: 599px)': {
      top: 60,
      right: 15,
      alignItems: 'flex-end',
    },
  },
  north: {
    backgroundColor: theme.colorBackground,
    borderRadius: 10,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
    width: 35,
    height: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.colorText,
    fontWeight: 700,
    pointerEvents: 'all',
  },
  northIcon: {
    fontWeight: 1000,
    fontSize: 13,
    marginBottom: -8,
  },
  zoomContainer: {
    marginTop: mapNavigationMarginTop,
    backgroundColor: theme.colorBackground,
    width: 35,
    borderRadius: 10,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
    pointerEvents: 'all',
  },
  zoomButtom: {
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 22,
    color: theme.colorText,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: `${theme.colorText}20`,
      transition: 'background-color 0.2s ease',
      cursor: 'pointer',

      '&:first-of-type': {
        borderRadius: '10px 10px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 10px 10px',
      },
    },
    '&:active': {
      backgroundColor: `${theme.colorText}40`,
      transition: 'background-color 0.2s ease',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: `none`,
    },
  },
  scale: {
    '@media (max-width: 1047px)': {
      marginBottom: 10,
    },
    '@media (max-width: 599px)': {
      marginBottom: 30,
    },
    '& .mapboxgl-ctrl-scale': {
      color: theme.colorText,
      borderColor: theme.colorText,
      backgroundColor: 'transparent',
      borderLeft: 'none',
      borderRight: 'none',

      '&::before': {
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 1,
        height: '50%',
        borderLeft: `solid 2px ${theme.colorText}`,
        borderBottom: 'none',
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        right: -2,
        bottom: -2,
        width: 1,
        height: '50%',
        borderLeft: `solid 2px ${theme.colorText}`,
        borderBottom: 'none',
      },
    },
  },
  creditsContainer: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    display: 'flex',
    alignItems: 'flex-end',
    transform: 'translateX(-50%)',
  },
  creditLogo: {
    '@media (max-width: 599px)': {
      marginBottom: 20,
    },
  },
}));

export default useStyles;
