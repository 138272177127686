import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 600px)': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  href: {
    textDecoration: 'inherit',
    color: theme.colorText,
    cursor: 'pointer',
    transition: 'color 0.2s ease',

    '&:hover': {
      color: theme.colorSecondary,
    },
    '&:focus': {
      outline: `none`,
    },
  },
}));

export default useStyles;
