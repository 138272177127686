/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useTheme } from 'react-jss';
import FilterSelect from '../../../components/filter-select';
import FiltersContext from '../../../context/filtering';
import useStyles from '../styles';

/**
 * Provides the data and components of the indigenous filters.
 */
export default function IndigenousFilters() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { options, values, setters, loads } = useContext(FiltersContext);

  return (
    <>
      <div className={classes.filterItem}>
        <FilterSelect
          filterName="Território"
          disabled
          options={{ 0: { translation: '-' } }}
          value="0"
          setValue={() => {}}
        />
      </div>
      <div className={classes.filterItem}>
        <FilterSelect
          filterName="Escala"
          disabled
          options={{ 0: { translation: '-' } }}
          value="0"
          setValue={() => {}}
        />
      </div>
      <div className={classes.filterItem}>
        <FilterSelect
          filterName="Comunidade"
          disabled
          options={{ 0: { translation: '-' } }}
          value="0"
          setValue={() => {}}
        />
      </div>
      <div className={classes.filterItem}>
        <FilterSelect
          filterName="Período"
          disabled
          options={{ 0: { translation: '-' } }}
          value="0"
          setValue={() => {}}
        />
      </div>
    </>
  );
}
