/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from 'react-jss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import { FiltersProvider } from '../../context/filtering';
import ProjectionsStatistics from './projections/statistics';
import ProjectionsFilters from './projections/filters';
import IsolationFilters from './isolation/filters';
import IndigenousFilters from './indigenous/filters';
import IsolationStatistics from './isolation/statistics';
import IndigenousStatistics from './indigenous/statistics';
import CasesFilters from './cases/filters';
import CasesStatistics from './cases/statistics';
import CasesMap from './cases/map';
import CasesLegend from './cases/legend';
import { MapProvider } from '../../context/mapping';
import { LoadingProvider } from '../../context/loading';
import MapView from '../../components/map-view';
import ProjectionsLegend from './projections/legend';
import ProjectionsMap from './projections/map';
import VaccinationFilters from './vaccination/filters';
import VaccinationStatistics from './vaccination/statistics';
import VaccinationMap from './vaccination/map';
import VaccinationLegend from './vaccination/legend';
import CasesLoader from './cases/loader';
import ProjectionsLoader from './projections/loader';
import VaccinationLoader from './vaccination/loader';

/**
 * This page has all the necessary structure to accommodate the map,
 * filters and statistics responsively according to the selected subpage.
 */
export default function Map() {
  /**
   * This useState stores the opening state of the filter.
   */
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const isBreak = useMediaQuery('(max-width:999px)');
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const handlers = useSwipeable({
    onSwipedDown: () => setFilterIsOpen(true),
    onSwipedUp: () => setFilterIsOpen(false),
  });
  const location = useLocation();
  const statisticsWrapperRef = useRef();

  /**
   * This useEffect controls the collapsable filter according to the breakpoint.
   */
  useEffect(() => {
    if (isBreak) {
      setFilterIsOpen(false);
    } else {
      setFilterIsOpen(true);
    }
  }, [isBreak]);

  /**
   * This function returns 'filters', 'statistics' or 'map' components according to the page path.
   */
  const getComponents = (componentType) => {
    if (componentType === 'filters') {
      switch (location.pathname) {
        case '/cases':
          return <CasesFilters />;
        case '/projections':
          return <ProjectionsFilters />;
        case '/isolation':
          return <IsolationFilters />;
        case '/indigenous':
          return <IndigenousFilters />;
        case '/vaccination':
          return <VaccinationFilters />;
        default:
          return <></>;
      }
    } else if (componentType === 'statistics') {
      switch (location.pathname) {
        case '/cases':
          return <CasesStatistics />;
        case '/projections':
          return <ProjectionsStatistics />;
        case '/isolation':
          return <IsolationStatistics />;
        case '/indigenous':
          return <IndigenousStatistics />;
        case '/vaccination':
          return <VaccinationStatistics />;
        default:
          return <></>;
      }
    } else if (componentType === 'map') {
      switch (location.pathname) {
        case '/cases':
          return <CasesMap />;
        case '/projections':
          return <ProjectionsMap />;
        case '/vaccination':
          return <VaccinationMap />;
        default:
          return <></>;
      }
    } else if (componentType === 'legend') {
      switch (location.pathname) {
        case '/cases':
          return <CasesLegend />;
        case '/projections':
          return <ProjectionsLegend />;
        case '/vaccination':
          return <VaccinationLegend />;
        default:
          return <></>;
      }
    } else if (componentType === 'loader') {
      switch (location.pathname) {
        case '/cases':
          return <CasesLoader />;
        case '/projections':
          return <ProjectionsLoader />;
        case '/vaccination':
          return <VaccinationLoader />;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  /**
   * This function handle a click at the statistics header element.
   */
  function handleStatisticsHeaderClick() {
    const { scrollTop } = statisticsWrapperRef.current;

    if (scrollTop < 200)
      statisticsWrapperRef.current.scrollTo({ top: 300, behavior: 'smooth' });
  }

  return (
    <LoadingProvider>
      <FiltersProvider>
        <div ref={statisticsWrapperRef} className={classes.wrapper}>
          {getComponents('loader')}
          <div className={classes.column}>
            <div className={classes.filters}>
              <Collapse style={{ width: '100%' }} in={filterIsOpen}>
                <div className={classes.filtersItens}>
                  {getComponents('filters')}
                </div>
              </Collapse>
              <div
                {...handlers}
                className={classes.filterFooter}
                onClick={() => setFilterIsOpen(!filterIsOpen)}
                role="button"
                onKeyDown={() => setFilterIsOpen(!filterIsOpen)}
                tabIndex={0}
              >
                <Typography style={{ color: theme.colorText }}>
                  {t('map_filters')}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setFilterIsOpen(!filterIsOpen)}
                >
                  {filterIsOpen ? (
                    <ExpandLessRoundedIcon style={{ color: theme.colorText }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: theme.colorText }} />
                  )}
                </IconButton>
              </div>
            </div>
            <div className={classes.map}>
              <MapProvider>
                <MapView navigationChildren={getComponents('legend')}>
                  {getComponents('map')}
                </MapView>
              </MapProvider>
            </div>
          </div>
          <div className={classes.statisticsBar}>
            <div
              className={classes.statisticsHeader}
              role="button"
              onClick={handleStatisticsHeaderClick}
              onKeyDown={handleStatisticsHeaderClick}
              tabIndex={0}
            >
              <Typography style={{ color: theme.colorText }}>
                {t('map_statistics')}
              </Typography>
              <ExpandLessRoundedIcon style={{ color: theme.colorText }} />
            </div>
            {getComponents('statistics')}
          </div>
        </div>
      </FiltersProvider>
    </LoadingProvider>
  );
}
