import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { defaultOptions } from '../constants/general';

/**
 * This function seems to be redundant, but it's able to receive territory exceptions.
 * @param {A list containing scales options} scales
 * @param {The current selected territory} territory
 */
export default function useScales(scales, territory) {
  const [scale, setScale] = useState(defaultOptions.scale);
  const [scalesTranslations, setScalesTranslations] = useState({});

  /**
   * This useEffect register scales translations.
   */
  useEffect(() => {
    const territoryScales =
      scales && territory ? scales[territory.type] : false;
    let scaleSupport = false;

    if (territoryScales) {
      const scalesTranslationsAux = {};

      Object.keys(territoryScales).forEach((sc) => {
        scalesTranslationsAux[sc] = { translation: `scale_${sc}` };

        i18next.addResource(
          'pt',
          'translation',
          `scale_${sc}`,
          territoryScales[sc].pt
        );
        i18next.addResource(
          'en',
          'translation',
          `scale_${sc}`,
          territoryScales[sc].en
        );
        i18next.addResource(
          'es',
          'translation',
          `scale_${sc}`,
          territoryScales[sc].es
        );

        if (sc === scale) scaleSupport = true;
      });

      setScalesTranslations(scalesTranslationsAux);

      if (scaleSupport) {
        // This "if" does the scale history function:
        if (territory.defaultScale) setScale(territory.defaultScale);
      } else if (territory.type === 'region') {
        // Add exception to region
        setScale('state');
      } else {
        setScale(Object.keys(territoryScales)[0]);
      }
    }
  }, [scales, territory]);

  return [scale, setScale, scalesTranslations];
}
