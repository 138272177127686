import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    maxWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItem: 'center',
  },
  title: {
    padding: '15px 0px',
    color: theme.colorText,
  },
  subtitle: {
    color: theme.colorText,
  },
  icon: {
    height: 70,
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  readMore: {
    '&.MuiButton-outlined': {
      borderColor: theme.colorPrimary,
      borderRadius: 50,
    },
    '&.MuiButton-root': {
      color: theme.colorPrimary,
    },
  },
  readMoreText: {
    textTransform: 'none',
  },
}));

export default useStyles;
