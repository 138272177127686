import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useStyles from './styles';

export default function HomeMethodologyCard({
  icon,
  title,
  subtitle,
  className,
  buttonText,
  link,
}) {
  HomeMethodologyCard.defaultProps = {
    className: '',
  };

  HomeMethodologyCard.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string,
    link: PropTypes.string.isRequired,
  };

  const classes = useStyles();

  return (
    <div className={classNames(className, classes.container)}>
      <div className={classes.cardWrapper}>
        <img className={classes.icon} src={icon} alt={title} />
        <Typography
          variant="h5"
          style={{ fontWeight: 300 }}
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography style={{ fontWeight: 300 }} className={classes.subtitle}>
          {subtitle}
        </Typography>
        <a
          rel="noreferrer"
          href={link}
          target="_blank"
          className={classes.readMore}
        >
          <Typography className={classes.readMoreText}>{buttonText}</Typography>
        </a>
      </div>
    </div>
  );
}
