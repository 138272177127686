import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  list: {
    '& .MuiPaper-root': {
      backgroundColor: theme.colorSecondaryBackground,
      color: theme.colorText,
    },
    '& .MuiDrawer-paper': {
      width: '100%',
      maxWidth: '300px',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 36,
    },
    '& .Mui-selected': {
      '&:hover': {
        backgroundColor: theme.colorTertiaryBackground,
      },
      '&::before': {
        content: "''",
        position: 'absolute',
        height: '100%',
        width: 5,
        backgroundColor: ({ selectedColor }) => selectedColor,
        borderRadius: '0px 5px 5px 0px',
        left: 0,
      },
      transition: 'all 0.2s ease',
    },
  },
  text: {
    fontSize: '1rem',
    textTransform: 'none',
  },
  iconButton: {
    color: theme.colorText,
  },
  menuPopOver: {
    '& .MuiPaper-root': {
      backgroundColor: theme.colorSecondaryBackground,
      color: theme.colorText,
      marginLeft: 11,
      width: 210,
    },
  },
  platformLogoWrapper: {
    height: 35,
    width: 35,
    marginRight: 5,
    position: 'relative',
  },
  platformLogo: {
    height: 35,
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  platformName: {
    color: theme.colorText,
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 300,
    position: 'relative',
    marginBottom: 5,

    '@media (max-width: 250px)': {
      display: 'none',
    },
  },
  platformSubtitle: {
    color: theme.colorText,
    fontSize: 10,
    fontFamily: 'Roboto',
    position: 'absolute',
    right: 2,
    opacity: 0.6,
    bottom: -7,
    fontStyle: 'italic',
    textAlign: 'right',
    fontWeight: 400,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
    marginRight: 5,
    userSelect: 'none',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
    justifyContent: 'space-between',
    margin: '0px 15px',
    marginBottom: 5,
  },
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
  },
  mapbiomasLogo: {
    height: 30,
    marginRight: 5,
  },
}));

export default useStyles;
