import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Brightness4, Brightness7, Close, Translate } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './styles';
import { availableMaps } from '../../../constants/general';
import { lightTheme } from '../../../constants/themes';
import ThemeContext from '../../../context/theming';
import platformLogo from '../../../assets/images/logo.svg';
import platformLogo2 from '../../../assets/images/logo2.svg';

const availableLanguages = i18next.options.resources;

/**
 * The HeaderDrawer component creates the drawer available on header bar.
 */
function HeaderDrawer({ open, onClose, selectedColor }) {
  HeaderDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedColor: PropTypes.string.isRequired,
  };

  const theme = useTheme();
  const classes = useStyles({ selectedColor, theme });
  const { t } = useTranslation();
  const { selectedTheme, switchTheme } = useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();

  const [translationMenuAnchor, setTranslationMenuAnchor] = useState(null);

  /**
   * This function opens the traslation menu.
   * @param {Click} event
   */
  function handleTranslationMenuClick(event) {
    setTranslationMenuAnchor(event.currentTarget);
  }

  /**
   * This function closes the translation menu.
   */
  function handleTranslationMenuClose() {
    setTranslationMenuAnchor(null);
  }

  /**
   * This function handle logo click.
   */
  function handleLogoClick() {
    history.push('/');
  }

  return (
    <Drawer
      classes={{ root: classes.list }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <List>
        <div className={classes.topBar}>
          <div
            role="button"
            className={classes.logoWrapper}
            onClick={handleLogoClick}
            onKeyDown={handleLogoClick}
            tabIndex="0"
          >
            <div className={classes.platformLogoWrapper}>
              <img
                className={classes.platformLogo}
                style={{
                  opacity: location.pathname === '/vaccination' ? 1 : 0,
                }}
                src={platformLogo2}
                alt={t('general_platformName')}
              />
              <img
                className={classes.platformLogo}
                style={{
                  opacity: location.pathname === '/vaccination' ? 0 : 1,
                }}
                src={platformLogo}
                alt={t('general_platformName')}
              />
            </div>
            <div className={classes.platformName}>
              {t('general_platformName')}
              <div className={classes.platformSubtitle}>
                {t('general_platformSubtitle')}
              </div>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <Close className={classes.iconButton} />
          </IconButton>
        </div>
        <Divider />
        <ListItem
          button
          onClick={() => {
            history.push('/');
            onClose();
          }}
          selected={location.pathname === '/'}
        >
          <ListItemText primary={t('header_startPageButton')} />
        </ListItem>
        {/* <ListItem
          button
          onClick={() => {
            history.push('/reports');
            onClose();
          }}
          selected={location.pathname === '/reports'}
        >
          <ListItemText primary={t('header_reportsPageButton')} />
        </ListItem> */}
        <Divider />
        {Object.keys(availableMaps).map((map) => (
          <ListItem
            key={map}
            button
            onClick={() => {
              history.push(availableMaps[map].url);
              onClose();
            }}
            selected={location.pathname === availableMaps[map].url}
          >
            <ListItemText primary={t(availableMaps[map].translation)} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={handleTranslationMenuClick}>
          <ListItemIcon>
            <Translate className={classes.iconButton} />
          </ListItemIcon>
          <ListItemText primary={t('general_languageName')} />
        </ListItem>
        <ListItem button onClick={switchTheme}>
          <ListItemIcon>
            {selectedTheme === lightTheme ? (
              <Brightness4 className={classes.iconButton} />
            ) : (
              <Brightness7 className={classes.iconButton} />
            )}
          </ListItemIcon>
          <ListItemText primary={t('header_changeThemeMenuButton')} />
        </ListItem>
      </List>
      <Menu
        id="translation-menu"
        anchorEl={translationMenuAnchor}
        open={Boolean(translationMenuAnchor)}
        onClose={handleTranslationMenuClose}
        PopoverClasses={{ root: classes.menuPopOver }}
      >
        {Object.keys(availableLanguages).map((language) => (
          <MenuItem
            key={language}
            onClick={() => {
              i18next.changeLanguage(language);
              handleTranslationMenuClose();
            }}
          >
            {availableLanguages[language].translation.general_languageName}
          </MenuItem>
        ))}
      </Menu>
    </Drawer>
  );
}

export default HeaderDrawer;
