/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import YouTube from 'react-youtube';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import virusImg from '../../assets/images/virus.svg';
import helloBackgroundImg from '../../assets/images/home-bg1.svg';
import portalCasesImg from '../../assets/images/home-cases-vector.svg';
import methodologyCardDataImg from '../../assets/images/home-data-vector.svg';
import portalProjectionImg from '../../assets/images/home-projection-vector.svg';
import methodologyCardProjectionsImg from '../../assets/images/home-projection-card-vector.svg';
import methodologyCardPublicationsImg from '../../assets/images/home-publications-vector.svg';
import portalVaccineImg from '../../assets/images/home-vaccine-vector.svg';
import ufbaPartner from '../../assets/images/ufba.png';
import uescPartner from '../../assets/images/uesc.png';
import uefsPartner from '../../assets/images/logo-uefs.png';
import unebPartner from '../../assets/images/uneb.png';
import ifbaPartner from '../../assets/images/ifba.png';
import brasilioPartner from '../../assets/images/brasilio.png';
import geodatinPartner from '../../assets/images/logo-geodatin.png';
import arapyauPartner from '../../assets/images/arapyau.png';
import solvedPartner from '../../assets/images/solved.png';
import ppgmPartner from '../../assets/images/ppgm.png';
import cimatecPartner from '../../assets/images/climatec.png';
import mapbiomasPartner from '../../assets/images/mapbiomas-partner.png';
import skollPartner from '../../assets/images/skoll.png';
import institutionalBackgroundImg from '../../assets/images/home-bg2.svg';
import institutionalNotebookImg from '../../assets/images/home-notebook.png';
import collaboratorsBackgroundImg from '../../assets/images/home-bg3.svg';
import useStyles from './styles';
import HomePortalItem from '../../components/home-portal-item';
import HomeMethodologyCard from '../../components/home-methodology-card';
import platformLogo from '../../assets/images/logo.svg';
import HomeFooterLinks from '../../components/home-footer-links';
import HomeCollaborator from '../../components/home-collaborator';

import matheusTelesImg from '../../assets/images/collaborators/matheusteles.jpeg';
import rafaelAzevedoImg from '../../assets/images/collaborators/rafaelazevedo.jpeg';
import hildebrandoNetoImg from '../../assets/images/collaborators/hildebrandoneto.jpg';
import iagoMachadoImg from '../../assets/images/collaborators/iagomachado.jpeg';
import diegoCostaImg from '../../assets/images/collaborators/diegocosta.jpeg';
import rodrigoVasconcelosImg from '../../assets/images/collaborators/rodrigovasconcelos.png';
import soltanGalanoImg from '../../assets/images/collaborators/soltangalano.jpeg';
import nerivaldoAfonsoImg from '../../assets/images/collaborators/nerivaldoafonso.jpeg';
import alvaroFernandesImg from '../../assets/images/collaborators/alvarofernandes.jpeg';
import tassoAzevedoImg from '../../assets/images/collaborators/tassoazevedo.jpeg';
import washingtonFrancaImg from '../../assets/images/collaborators/washingtonfranca.jpg';
import adrianGonzalezImg from '../../assets/images/collaborators/adriangonzalez.jpeg';
import cesarDinizImg from '../../assets/images/collaborators/cesardiniz.png';
import angeloConradoImg from '../../assets/images/collaborators/angeloConrado.jpg';
import gesilSampaioImg from '../../assets/images/collaborators/gesilSampaio.jpeg';
import jocimaraLobaoImg from '../../assets/images/collaborators/jocimaraLobao.png';
import joselisaChavesImg from '../../assets/images/collaborators/joselisaChaves.jpeg';
import rodrigoTripodiImg from '../../assets/images/collaborators/rodrigoTripodi.jpg';
import elaineCambuiImg from '../../assets/images/collaborators/elaineCambui.jpg';
import joseBertolinoImg from '../../assets/images/collaborators/joseBertolino.png';
import mateusSilvaImg from '../../assets/images/collaborators/mateusSouza.jpg';
import ellenMonteiroImg from '../../assets/images/collaborators/ellenMonteiro.jpg';
import joseGarciaImg from '../../assets/images/collaborators/joseGarcia.jpg';
import evandroNascimentoImg from '../../assets/images/collaborators/evandronascimento.jpg';
import aristeuVieiraImg from '../../assets/images/collaborators/aristeuvieira.jpg';
import hugoSabaImg from '../../assets/images/collaborators/hugoSaba.png';
import marcioValencaImg from '../../assets/images/collaborators/marcioValenca.jpeg';

/**
 * This is the application home page.
 */
function Home() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.helloSection}>
        <img
          className={classes.helloBackground}
          src={helloBackgroundImg}
          alt="bg1"
        />
        <div
          className={classNames(classes.helloWrapper, classes.sectionWrapper)}
        >
          <div className={classes.helloLeftColumn}>
            <Typography
              variant="h3"
              className={classes.title}
              style={{ fontWeight: 300 }}
            >
              {t('homePage_hello_title')}
            </Typography>
            <Typography
              className={classes.subtitle}
              style={{ fontWeight: 300 }}
            >
              {t('homePage_hello_subtitle')}
            </Typography>
            <Button
              variant="outlined"
              className={classes.helloButton}
              onClick={() => history.push('/cases')}
            >
              <Typography className={classes.helloButtonText}>
                {t('homePage_hello_button')}
              </Typography>
            </Button>
          </div>
          <div className={classes.helloRightColumn}>
            <object
              className={classes.helloVirusImg}
              type="image/svg+xml"
              data={virusImg}
            >
              <img src={virusImg} alt="virus" />
            </object>
          </div>
        </div>
      </div>
      <div id="portal" className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <div className={classes.portalPresentation}>
            <Typography
              variant="h4"
              className={classes.title}
              style={{ fontWeight: 300 }}
            >
              {t('homePage_portal_title')}
            </Typography>
            <Typography
              className={classes.subtitle2}
              style={{ fontWeight: 300 }}
              align="center"
            >
              {t('homePage_portal_subtitle')}
            </Typography>
          </div>
          <div className={classes.portalItemsContainer}>
            <HomePortalItem
              icon={portalCasesImg}
              title={t('homePage_portal_casesTitle')}
              subtitle={t('homePage_portal_casesSubtitle')}
              className={classes.portalItem}
              text={t('homePage_portal_card_button')}
              link="/cases"
            />
            {/* <HomePortalItem
              icon={portalProjectionImg}
              title={t('homePage_portal_projectionTitle')}
              subtitle={t('homePage_portal_projectionSubtitle')}
              className={classes.portalItem}
              text={t('homePage_portal_card_button')}
              link="/projections"
            /> */}
            <HomePortalItem
              icon={portalVaccineImg}
              title={t('homePage_portal_vaccinationTitle')}
              subtitle={t('homePage_portal_vaccinationSubtitle')}
              text={t('homePage_portal_card_button')}
              link="/vaccination"
            />
          </div>
        </div>
      </div>
      <div id="institutional" className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <div className={classes.institutionalContentWrapper}>
            <div className={classes.institutionalVideoWrapper}>
              <img
                className={classes.institutionalBackground}
                src={institutionalBackgroundImg}
                alt="bg2"
              />
              <div className={classes.institutionalVideoResolution}>
                <img
                  className={classes.institutionalNotebook}
                  src={institutionalNotebookImg}
                  alt="notebook"
                />
                <YouTube
                  videoId="XC5q5qVP1gI"
                  className={classes.institutionalVideo}
                />
              </div>
            </div>
            <div className={classes.institutionalTextWrapper}>
              <Typography
                variant="h4"
                className={classes.title}
                style={{ fontWeight: 300 }}
              >
                {t('homePage_institutional_title')}
              </Typography>
              <Typography
                className={classes.institutionalText}
                style={{ fontWeight: 300 }}
              >
                {t('homePage_institutional_text')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div id="method" className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <div className={classes.methodologyPresentation}>
            <Typography
              variant="h4"
              className={classes.title}
              style={{ fontWeight: 300 }}
            >
              {t('homePage_methodology_title')}
            </Typography>
            <Typography
              className={classes.subtitle2}
              style={{ fontWeight: 300 }}
              align="center"
            >
              {t('homePage_methodology_subtitle')}
            </Typography>
          </div>
          <div className={classes.methodologyCardsContainer}>
            <HomeMethodologyCard
              icon={methodologyCardDataImg}
              title={t('homePage_methodology_card_dataTitle')}
              subtitle={t('homePage_methodology_card_dataSubtitle')}
              buttonText={t('homePage_methodology_card_button')}
              className={classes.methodologyCard}
              link="/method-data"
            />
            <HomeMethodologyCard
              icon={methodologyCardProjectionsImg}
              title={t('homePage_methodology_card_projectionsTitle')}
              subtitle={t('homePage_methodology_card_projectionsSubtitle')}
              buttonText={t('homePage_methodology_card_button')}
              className={classes.methodologyCard}
              link="/method-projections"
            />
            <HomeMethodologyCard
              icon={methodologyCardPublicationsImg}
              title={t('homePage_methodology_card_publicationsTitle')}
              subtitle={t('homePage_methodology_card_publicationsSubtitle')}
              buttonText={t('homePage_methodology_card_button')}
              className={classes.methodologyCard}
              link="/method-publications"
            />
          </div>
        </div>
      </div>
      <div id="partners" className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <div className={classes.partnersPresentation}>
            <Typography
              variant="h4"
              className={classes.title}
              style={{ fontWeight: 300 }}
            >
              {t('homePage_partners_title')}
            </Typography>
            <Typography
              className={classes.subtitle2}
              style={{ fontWeight: 300 }}
              align="center"
            >
              {t('homePage_partners_subtitle')}
            </Typography>
          </div>
          <div className={classes.partners}>
            <div className={classes.linePartners}>
              <a
                rel="noopener noreferrer"
                href="http://www.mapbiomas.org"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  style={{ height: '80px' }}
                  className={classes.partner}
                  src={mapbiomasPartner}
                  alt="mapbiomas"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://geodatin.com"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  style={{ height: '80px' }}
                  className={classes.partner}
                  src={geodatinPartner}
                  alt="geodatin"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.skoll.org"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  style={{ height: '110px' }}
                  className={classes.partner}
                  src={skollPartner}
                  alt="skoll"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.arapyau.org.br/"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '50px' }}
                  src={arapyauPartner}
                  alt="arapyau"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.solved.eco.br"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '60px' }}
                  src={solvedPartner}
                  alt="solved"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://ppgm.uefs.br/"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '60px' }}
                  src={ppgmPartner}
                  alt="ppgm"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.uefs.br"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '80px', opacity: 0.5 }}
                  src={uefsPartner}
                  alt="uefs"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.ufba.br"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '80px', opacity: 0.5 }}
                  src={ufbaPartner}
                  alt="ufba"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.uesc.br"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '80px', opacity: 0.5 }}
                  src={uescPartner}
                  alt="uesc"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.portal.uneb.br"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '80px', opacity: 0.5 }}
                  src={unebPartner}
                  alt="uneb"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.portal.ifba.edu.br"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '80px' }}
                  src={ifbaPartner}
                  alt="ifba"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.senaicimatec.com.br/"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  className={classes.partner}
                  style={{ height: '60px' }}
                  src={cimatecPartner}
                  alt="cimatec"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="http://www.brasil.io"
                target="_blank"
                className={classes.imageLink}
              >
                <img
                  style={{ height: '60px' }}
                  className={classes.partner}
                  src={brasilioPartner}
                  alt="brasilio"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="collaborators" className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <div className={classes.collaboratorsPresentation}>
            <Typography
              variant="h4"
              className={classes.title}
              style={{ fontWeight: 300 }}
            >
              {t('homePage_collaborators_title')}
            </Typography>
            <Typography
              className={classes.subtitle2}
              style={{ fontWeight: 300 }}
              align="center"
            >
              {t('homePage_collaborators_text')}
            </Typography>
          </div>
          <div className={classes.collaboratorsContent}>
            <div className={classes.collaboratorsWrapper}>
              <img
                className={classes.collaboratorsBackground}
                src={collaboratorsBackgroundImg}
                alt="bg3"
              />
            </div>
            <div className={classes.collaboratorsImages}>
              <HomeCollaborator
                name="Matheus Teles de Oliveira"
                image={matheusTelesImg}
                link="https://www.linkedin.com/in/matheustdo/"
                instituition="Geodatin e UEFS"
              />
              <HomeCollaborator
                name="Rafael dos Reis Azevedo"
                image={rafaelAzevedoImg}
                link="https://www.linkedin.com/in/rrazvd/"
                instituition="Geodatin e UEFS"
              />
              <HomeCollaborator
                name="Hildebrando Simões de Araújo Neto"
                image={hildebrandoNetoImg}
                link="https://github.com/Neto1904"
                instituition="Geodatin e UEFS"
              />
              <HomeCollaborator
                name="Iago Machado da Conceição Silva"
                image={iagoMachadoImg}
                link="https://www.linkedin.com/in/iagomachadocs"
                instituition="Geodatin e UEFS"
              />
              <HomeCollaborator
                name="Diêgo Pereira Costa"
                image={diegoCostaImg}
                link="https://www.linkedin.com/in/costapdiego"
                instituition="Geodatin"
              />
              <HomeCollaborator
                name="Soltan Galano Duverger"
                image={soltanGalanoImg}
                link="https://www.linkedin.com/in/soltan-galano-duverger-2a10907b/"
                instituition="Geodatin"
              />
              <HomeCollaborator
                name="Rodrigo Nogueira de Vasconcelos"
                image={rodrigoVasconcelosImg}
                link="http://lattes.cnpq.br/0476354655194997"
                instituition="Geodatin e UEFS"
              />
              <HomeCollaborator
                name="Nerivaldo Afonso Santos"
                image={nerivaldoAfonsoImg}
                link="http://lattes.cnpq.br/6106157345879756"
                instituition="Geodatin"
              />
              <HomeCollaborator
                name="Tasso Azevedo"
                image={tassoAzevedoImg}
                link="https://www.linkedin.com/in/tassoazevedo/"
                instituition="MapBiomas"
              />
              <HomeCollaborator
                name="Washington de J. S. da Franca Rocha"
                image={washingtonFrancaImg}
                link="http://lattes.cnpq.br/6817204233903341"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Álvaro Fernandes de Abreu Justen"
                image={alvaroFernandesImg}
                link="http://brasil.io/"
                instituition="Brasil.io"
              />
              <HomeCollaborator
                name="Adrian González Cantillo"
                image={adrianGonzalezImg}
                link="https://www.linkedin.com/in/adrian-gonz%C3%A1lez-a354a3160/"
                instituition="Geodatin"
              />
              <HomeCollaborator
                name="Angelo Conrado Loula"
                image={angeloConradoImg}
                link="http://www.artificial.eng.br/"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Gesil Sampaio Amarante Segundo"
                image={gesilSampaioImg}
                link="http://lattes.cnpq.br/2560106052820991"
                instituition="UESC"
              />
              <HomeCollaborator
                name="Jocimara Souza Britto Lobão"
                image={jocimaraLobaoImg}
                link="http://lattes.cnpq.br/8965493991900284"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Joselisa Maria Chaves"
                image={joselisaChavesImg}
                link="http://lattes.cnpq.br/3589599687371587"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Rodrigo Tripodi Calumby"
                image={rodrigoTripodiImg}
                link="http://www.rtcalumby.com.br"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Elaine Cristina Cambui Barbosa"
                image={elaineCambuiImg}
                instituition="UFBA"
              />
              <HomeCollaborator
                name="José Gabriel Bertolino de Castro"
                image={joseBertolinoImg}
                link="http://bertolinocastro.github.io/"
                instituition="UFBA"
              />
              <HomeCollaborator
                name="Mateus Souza Silva"
                image={mateusSilvaImg}
                link="http://lattes.cnpq.br/7561866784796981"
                instituition="UFBA"
              />
              <HomeCollaborator
                name="Jose Garcia Vivas Miranda"
                image={joseGarciaImg}
                link="http://lattes.cnpq.br/1608472474770322"
                instituition="UFBA"
              />
              <HomeCollaborator
                name="Cesar Guerreiro Diniz"
                image={cesarDinizImg}
                link="https://solved.eco.br"
                instituition="Solved"
              />
              <HomeCollaborator
                name="Ellen Monteiro Ribeiro Santos"
                image={ellenMonteiroImg}
                link="http://lattes.cnpq.br/7268530723132968"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Evandro do Nascimento Silva"
                image={evandroNascimentoImg}
                link="http://lattes.cnpq.br/2696325029348427"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Aristeu Vieira da Silva"
                image={aristeuVieiraImg}
                link="http://br.linkedin.com/pub/aristeu-vieira-da-silva/29/98b/a29"
                instituition="UEFS"
              />
              <HomeCollaborator
                name="Hugo Saba"
                image={hugoSabaImg}
                link="http://lattes.cnpq.br/1966167015825708"
                instituition="UNEB"
              />
              <HomeCollaborator
                name="Marcio Luiz Valença"
                image={marcioValencaImg}
                link="http://lattes.cnpq.br/0777733127275321"
                instituition="IFBA"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footerContainer}>
        <div
          className={classNames(classes.sectionWrapper, classes.footerElements)}
        >
          <div className={classes.footerContact}>
            <MailOutlineIcon />
            <a
              rel="noreferrer"
              target="_blank"
              href="mailto:portalcovid19@uefs.br"
              className={classes.footerContactHref}
            >
              <Typography style={{ fontWeight: 300 }} align="center">
                {t('homePage_footer_contact_message', {
                  email: 'portalcovid19@uefs.br',
                })}
              </Typography>
            </a>
          </div>
          <div className={classes.footerSeparator} />
          <div className={classes.footerLinks}>
            <HomeFooterLinks
              title={t('homePage_footer_links_platform_title')}
              links={[
                {
                  text: t('homePage_footer_links_platform_about'),
                  href: '#portal',
                },
                {
                  text: t('homePage_footer_links_platform_institutional'),
                  href: '#institutional',
                },
                {
                  text: t('homePage_footer_links_platform_method'),
                  href: '#method',
                },
                {
                  text: t('homePage_footer_links_platform_partners'),
                  href: '#partners',
                },
                {
                  text: t('homePage_footer_links_platform_collaborators'),
                  href: '#collaborators',
                },
              ]}
            />
            <HomeFooterLinks
              title={t('homePage_footer_links_method_title')}
              links={[
                {
                  text: t('homePage_footer_links_method_data'),
                  href: '/method-data',
                  target: '_blank',
                },
                {
                  text: t('homePage_footer_links_method_projections'),
                  href: '/method-projections',
                  target: '_blank',
                },
                {
                  text: t('homePage_footer_links_method_publications'),
                  href: '/method-publications',
                  target: '_blank',
                },
              ]}
            />
            <HomeFooterLinks
              title={t('homePage_footer_links_pages_title')}
              links={[
                {
                  text: t('homePage_footer_links_pages_cases'),
                  link: 'cases',
                },
                /* {
                  text: t('homePage_footer_links_pages_projections'),
                  link: 'projections',
                }, */
                {
                  text: t('homePage_footer_links_pages_vaccination'),
                  link: 'vaccination',
                },
              ]}
            />
            <HomeFooterLinks
              title={t('homePage_footer_links_contact_title')}
              links={[
                {
                  text: t('homePage_footer_links_contact_email'),
                  href: 'mailto:portalcovid19@uefs.br',
                },
              ]}
            />
          </div>
          <div className={classes.footerSeparator} />
          <div className={classes.footerLastElements}>
            <div className={classes.platformLogoContainer}>
              <img
                className={classes.platformLogo}
                src={platformLogo}
                alt={t('general_platformName')}
              />
              <div className={classes.platformName}>
                {t('general_platformName')}
              </div>
            </div>
            <div className={classes.footerLastText}>
              <Typography style={{ fontWeight: 300 }}>
                {t('homePage_footer_poweredBy')}{' '}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://mapbiomas.org/"
                  className={classes.href}
                >
                  MapBiomas ©
                </a>
              </Typography>
              <Typography style={{ fontWeight: 300 }}>
                {t('homePage_footer_developedBy')}{' '}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="http://geodatin.com/"
                  className={classes.href}
                >
                  Geodatin ©
                </a>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
