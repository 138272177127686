/* eslint-disable no-unused-vars */
import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import equations1 from '../../../assets/images/projections_equations1.png';
import equations2 from '../../../assets/images/projections_equation2.png';
import equations3 from '../../../assets/images/projections_equations3.png';
import Document from '../../../components/document';
import useStyles from './styles';

/**
 * This page shows the projections methodology.
 */
export default function MethodProjections() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <Document>
      <Document.Title>{t('projectionsMethod:sectionTitle_0')}</Document.Title>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_1')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_0')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_2')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_1')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_1_1')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_2')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_3')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_4')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_5')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_6')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_7')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_8')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_9')}
      </Document.Paragraph>
      <img className={classes.equation1} src={equations1} alt="equations1" />
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_10')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_11')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_12')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_13')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_14')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_15')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_16')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_17')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_18')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_19')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_20')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_21')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_22')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_23')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_24')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_25')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_26')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_3')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_27')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_28')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_29')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_30')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_4')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_31')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_32')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_33')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_34')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_35')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_36')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_37')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_38')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_39')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_40')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_41')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_42')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_43')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_44')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_45')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_46')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_47')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_48')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_49')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_5')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_50')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_51')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_52')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_53')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_54')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_55')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_6')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_56')}
      </Document.Paragraph>
      <img className={classes.equation2} src={equations2} alt="equations2" />
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_57')}
      </Document.Paragraph>
      <img className={classes.equation2} src={equations3} alt="equations2" />
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_58')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('projectionsMethod:sectionTitle_8')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_59')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_60')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_61')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_62')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_63')}
      </Document.Paragraph>
      <Document.Paragraph>
        {t('projectionsMethod:paragraph_64')}
      </Document.Paragraph>
    </Document>
  );
}
