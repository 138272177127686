import { createUseStyles } from 'react-jss';
import { mapNavigationMarginTop } from '../../constants/sizes';

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'relative',
    marginTop: mapNavigationMarginTop,
    marginRight: 20,
    maxWidth: 300,
    pointerEvents: 'none',

    '@media (max-width: 599px)': {
      marginRight: 0,
      marginLeft: 20,
    },
  },
  closedContainer: {
    pointerEvents: 'all',
    position: 'absolute',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
    borderRadius: 10,
    width: 35,
    height: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colorBackground,
    fontSize: 12,
    color: theme.colorText,
    fontWeight: 700,

    '&:focus': {
      outline: `none`,
    },
    '&:hover': {
      cursor: 'pointer',

      '&::before': {
        content: "''",
        position: 'absolute',
        borderRadius: 10,
        width: 35,
        height: 35,
        backgroundColor: `${theme.colorText}20`,
        transition: 'background-color 0.2s ease',
      },
    },
    '&:active': {
      cursor: 'pointer',

      '&::after': {
        content: "''",
        position: 'absolute',
        borderRadius: 10,
        width: 35,
        height: 35,
        backgroundColor: `${theme.colorText}40`,
        transition: 'background-color 0.2s ease',
      },
    },
    '@media (max-width: 599px)': {
      marginRight: 0,
      right: 0,
    },
  },
  openedContainer: {
    backgroundColor: theme.colorBackground,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.colorText,
    fontWeight: 700,
    position: 'relative',
    padding: 10,
    pointerEvents: 'all',
  },
  closeButton: {
    pointerEvents: 'all',
    position: 'absolute',
    top: 0,
    borderRadius: 10,
    width: 35,
    height: 35,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.colorText,
    fontWeight: 700,

    '&:hover': {
      backgroundColor: `${theme.colorText}20`,
      transition: 'background-color 0.2s ease',
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: `${theme.colorText}40`,
      transition: 'background-color 0.2s ease',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: `none`,
    },
    '@media (max-width: 599px)': {
      right: 0,
    },
    '@media (min-width: 600px)': {
      left: 0,
    },
  },
  colorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '5px 0px',
    justifyContent: 'space-around',
  },
}));

export default useStyles;
