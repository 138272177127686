/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import PieChart from '../../../components/charts/pie-chart';
import PyramidChart from '../../../components/charts/pyramid-chart';
import RankingChart from '../../../components/charts/ranking-chart';
import SemiCircleChart from '../../../components/charts/semi-circle-chart';
import SeriesChart from '../../../components/charts/series-chart';
import GeneralStatistics from '../../../components/general-statistics';
import FiltersContext from '../../../context/filtering';
import LoaderContext from '../../../context/loading';
import api from '../../../services/api';
import useStyles from '../styles';

/**
 * Provides the data and components of the vaccination statistics.
 */
export default function VaccinationStatistics() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const { options, values, vaccinationLoads } = useContext(FiltersContext);
  const { setVaccinationLoading } = useContext(LoaderContext);

  /* general statistics */
  const [generalStatisticsRaw, setGeneralStatisticsRaw] = useState();
  const [generalStatistics, setGeneralStatistics] = useState();

  /* last update */
  const [lastUpdate, setLastUpdate] = useState();

  /* datatype serie on time */
  const [chartData, setChartData] = useState();

  /* ranking per territory */
  const [rankingData, setRankingData] = useState();
  const [rankingTotalPages, setRankingTotalPages] = useState();
  const [rankingPage, setRankingPage] = useState(1);

  /* gender pyramid  */
  const [chartGenderData, setGenderChartData] = useState();

  /* ranking per group */
  const [rankingGroupData, setRankingGroupData] = useState();
  const [rankingGroupTotalPages, setRankingGroupTotalPages] = useState();
  const [rankingGroupPage, setRankingGroupPage] = useState(1);

  /* vaccine pie-chart */
  const [chartVaccineData, setChartVaccineData] = useState();

  /* ethnicity semi-circle */
  const [chartEthnicityData, setChartEthnicityData] = useState();

  /**
   * This function prepare general statistics data to show the user through general statistics component.
   * @param {statistics data to prepare} data
   */
  function prepareStatisticsData(data) {
    const dataAux = { ...data };

    Object.keys(dataAux).forEach((statistic) => {
      if (dataAux[statistic]) {
        if (
          statistic === 'vaccinatedDose1PerPopulation' ||
          statistic === 'vaccinatedDose2PerPopulation'
        ) {
          dataAux[statistic] = `${t('general_number', {
            value: Number(dataAux[statistic]),
          })}%`;
        } else {
          dataAux[statistic] = t('general_number', {
            value: Number(dataAux[statistic]),
          });
        }
      } else {
        dataAux[statistic] = '-';
      }
    });

    return dataAux;
  }

  /**
   * This userEffect gets new general statistics data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      api
        .get(`/api/vaccination/statistics`, {
          params: {
            territoryType: values.territory.type,
            code: values.territory.code,
            day: options.vaccinationDates[values.vaccinationDate],
          },
        })
        .then(({ data }) => {
          if (isSubscribed) setGeneralStatisticsRaw(data);
        })
        .catch((error) => {});
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory,
    values.vaccinationDate,
    options.vaccinationDates,
    vaccinationLoads.options,
  ]);

  /**
   * This useEffect generate the general statistics component according to the translation and data, and gets last update.
   */
  useEffect(() => {
    if (generalStatisticsRaw) {
      setVaccinationLoading((rest) => ({ ...rest, generalStatistics: true }));
      setGeneralStatistics(prepareStatisticsData(generalStatisticsRaw));

      api
        .get(`/api/vaccination/lastUpdate`, {
          params: {
            territoryType: values.territory.type,
          },
        })
        .then(({ data }) => {
          setLastUpdate(data.date);
          setVaccinationLoading((rest) => ({
            ...rest,
            generalStatistics: false,
          }));
        })
        .catch((error) => {});
    }
  }, [t, generalStatisticsRaw]);

  /**
   * This userEffect updates series chart data.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, chartSeries: true }));
      api
        .get(`/api/vaccination/chart`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setChartData(data);
            setVaccinationLoading((rest) => ({ ...rest, chartSeries: false }));
          }
        })
        .catch((error) => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    vaccinationLoads.options,
    values.vaccinationDataType,
    values.territory,
    options.vaccinationDates,
    values.vaccinationDate,
  ]);

  /**
   * This userEffect gets new ranking group data every time the filter values are modified.
   */
  useEffect(() => {
    async function getRankingGroup() {
      const request = await api.get(`/api/vaccination/ranking/group`, {
        params: {
          dataType: values.vaccinationDataType,
          territoryType: values.territory.type,
          code: values.territory.code,
          scale: values.scale,
          day: options.vaccinationDates[values.vaccinationDate],
          daily: !!values.vaccinationDataType.match('Day'),
          doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
          page: 1,
        },
      });

      return request;
    }

    let isSubscribed = true;

    function handleData(data) {
      if (isSubscribed) {
        setRankingGroupTotalPages(data.pages);
        setRankingGroupData(data);
        setRankingGroupPage(1);
        if (data.cityPage > 0) setRankingGroupPage(data.cityPage);
        setVaccinationLoading((rest) => ({ ...rest, rankingGroup: false }));
      }
    }

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, rankingGroup: true }));

      getRankingGroup()
        .then(({ data }) => {
          handleData(data);
        })
        .catch((error) => {
          getRankingGroup().then(({ data }) => {
            handleData(data);
          });
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.vaccinationDataType,
    values.territory,
    values.scale,
    values.vaccinationDate,
    options.vaccinationDates,
    vaccinationLoads.options,
  ]);

  /**
   * This userEffect gets new ranking data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, rankingGroup: true }));
      api
        .get(`/api/vaccination/ranking/group`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
            page: rankingGroupPage,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingGroupData(data);
            setVaccinationLoading((rest) => ({ ...rest, rankingGroup: false }));
          }
        })
        .catch((error) => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [rankingGroupPage]);

  /**
   * This userEffect gets new ranking territory data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, rankingTerritory: true }));

      api
        .get(`/api/vaccination/ranking`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
            page: 1,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingTotalPages(data.pages);
            setRankingData(data);
            setRankingPage(1);
            if (data.cityPage > 0) setRankingPage(data.cityPage);
            setVaccinationLoading((rest) => ({
              ...rest,
              rankingTerritory: false,
            }));
          }
        })
        .catch((error) => {});
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.vaccinationDataType,
    values.territory,
    values.scale,
    values.vaccinationDate,
    options.vaccinationDates,
    vaccinationLoads.options,
  ]);

  /**
   * This userEffect gets new ranking territory data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      api
        .get(`/api/vaccination/ranking`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
            page: rankingPage,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingData(data);
          }
        })
        .catch((error) => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [rankingPage]);

  /**
   * This userEffect updates gender pyramid chart data.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, chartGender: true }));

      api
        .get(`/api/vaccination/chart/gender`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
          },
        })
        .then(({ data }) => {
          if (isSubscribed) setGenderChartData(data);
          setVaccinationLoading((rest) => ({ ...rest, chartGender: false }));
        })
        .catch((error) => {});
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    vaccinationLoads.options,
    values.vaccinationDataType,
    values.territory,
    options.vaccinationDates,
    values.vaccinationDate,
  ]);

  /**
   * This userEffect updates vaccine names chart data.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, chartVaccineNames: true }));
      api
        .get(`/api/vaccination/names`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setChartVaccineData(data);
            setVaccinationLoading((rest) => ({
              ...rest,
              chartVaccineNames: false,
            }));
          }
        })
        .catch((error) => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    vaccinationLoads.options,
    values.vaccinationDataType,
    values.territory,
    options.vaccinationDates,
    values.vaccinationDate,
  ]);

  /**
   * This userEffect updates ethnicity semi circle chart data.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (vaccinationLoads.options) {
      setVaccinationLoading((rest) => ({ ...rest, chartEthnicity: true }));
      api
        .get(`/api/vaccination/chart/ethnicity`, {
          params: {
            dataType: values.vaccinationDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            day: options.vaccinationDates[values.vaccinationDate],
            daily: !!values.vaccinationDataType.match('Day'),
            doseNumber: values.vaccinationDataType.replace(/\D/g, ''),
          },
        })
        .then(({ data }) => {
          if (isSubscribed) setChartEthnicityData(data);
          setVaccinationLoading((rest) => ({ ...rest, chartEthnicity: false }));
        })
        .catch((error) => {});
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    vaccinationLoads.options,
    values.vaccinationDataType,
    values.territory,
    options.vaccinationDates,
    values.vaccinationDate,
  ]);

  return useMemo(
    () => (
      <>
        {generalStatistics && (
          <div className={classes.statisticItem}>
            <GeneralStatistics
              data={generalStatistics}
              sources={[
                {
                  href: 'http://www.brasil.io',
                  name: 'brasilio',
                  rel: 'noopener noreferrer',
                },
              ]}
              lastUpdate={lastUpdate}
              firstColor={theme.colorTertiary}
              secondColor={theme.colorQuaternary}
            />
          </div>
        )}

        {chartData && (
          <div className={classes.statisticItem}>
            <SeriesChart data={chartData} color={theme.colorTertiary} />
          </div>
        )}

        {rankingData && (
          <div className={classes.statisticItem}>
            <RankingChart
              preTitle={t('rankingChart_territory_preTitle')}
              data={rankingData}
              totalPages={rankingTotalPages}
              page={rankingPage}
              setRankingPage={setRankingPage}
              color={theme.colorQuaternary}
            />
          </div>
        )}

        {rankingGroupData && (
          <div className={classes.statisticItem}>
            <RankingChart
              preTitle={t('rankingChart_groups_preTitle')}
              data={rankingGroupData}
              totalPages={rankingGroupTotalPages}
              page={rankingGroupPage}
              setRankingPage={setRankingGroupPage}
              color={theme.colorTertiary}
            />
          </div>
        )}

        {chartGenderData && (
          <div className={classes.statisticItem}>
            <PyramidChart data={chartGenderData} />
          </div>
        )}

        {chartGenderData && (
          <div className={classes.statisticItem}>
            <PieChart data={chartVaccineData} />
          </div>
        )}

        {chartEthnicityData && (
          <div className={classes.statisticItem}>
            <SemiCircleChart data={chartEthnicityData} />
          </div>
        )}
      </>
    ),
    [
      t,
      classes,
      generalStatistics,
      lastUpdate,
      chartData,
      rankingData,
      chartGenderData,
      chartEthnicityData,
      rankingTotalPages,
    ]
  );
}
