/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { Button, Icon, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
/* import InfoOutlined from '@material-ui/icons/InfoOutlined'; */
import useStyles from './styles';

/**
 * This component shows the general statistical data in two columns responsively.
 * @param {Object with statistics} data
 */
export default function GeneralStatistics({
  data,
  lastUpdate,
  firstColor,
  secondColor,
  sources,
}) {
  GeneralStatistics.defaultProps = {
    data: {},
    lastUpdate: undefined,
    firstColor: undefined,
    secondColor: undefined,
    sources: undefined,
  };

  GeneralStatistics.propTypes = {
    data: PropTypes.shape(),
    lastUpdate: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.shape()),
    firstColor: PropTypes.string,
    secondColor: PropTypes.string,
  };

  const theme = useTheme();
  const classes = useStyles({ secondColor, theme });
  const { t } = useTranslation();

  /*   const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  }; */

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  /**
   * In this return, there are two iterations of object data: half to fill the first column
   * and other half to fill second column. The horizontal alternation of color in the numbers
   * is possible through the even or odd verification of the index.
   */
  return (
    <div className={classes.wrapper}>
      <div style={{ justifyContent: 'space-between' }} className={classes.row}>
        <div className={classes.column}>
          {Object.keys(data)
            .slice(0, Math.ceil(Object.keys(data).length / 2))
            .map((statistic, index) => (
              <div
                key={statistic}
                style={{ display: 'flex', flexFlow: 'column nowrap' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{ color: theme.colorText }}
                    variant="body2"
                  >
                    {t(`generalStatistics_${statistic}`)}
                  </Typography>

                  {/* <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    title={t(`generalStatisticsTip_${statistic}`)}
                  >
                    <InfoOutlined
                      onClick={handleTooltipOpen}
                      style={{
                        cursor: 'pointer',
                        marginLeft: '4px',
                        color: theme.colorText,
                        fontSize: '15px',
                      }}
                    />
                  </Tooltip> */}
                </div>

                <Typography
                  style={
                    index % 2 === 0
                      ? { color: firstColor }
                      : { color: secondColor }
                  }
                  variant="h4"
                >
                  {data[statistic]}
                </Typography>
              </div>
            ))}
        </div>
        <div className={classes.column}>
          {Object.keys(data)
            .slice(
              Math.ceil(Object.keys(data).length / 2),
              Object.keys(data).length
            )
            .map((statistic, index) => (
              <div
                key={statistic}
                style={{ display: 'flex', flexFlow: 'column nowrap' }}
              >
                <Typography style={{ color: theme.colorText }} variant="body2">
                  {t(`generalStatistics_${statistic}`)}
                </Typography>
                <Typography
                  style={
                    index % 2 !== 0
                      ? { color: firstColor }
                      : { color: secondColor }
                  }
                  variant="h4"
                >
                  {data[statistic]}
                </Typography>
              </div>
            ))}
        </div>
      </div>

      {lastUpdate && (
        <>
          <div className={classes.row}>
            <div>
              <Typography
                style={{ color: theme.colorSecondaryText }}
                variant="caption"
              >
                {t('generalStatistics_lastUpdate', {
                  date: new Date(lastUpdate),
                })}
              </Typography>
            </div>
          </div>

          {sources.map((source) => (
            <div key={source.name} className={classes.row}>
              <a
                rel={source.rel}
                href={source.href}
                target="_blank"
                className={classes.href}
              >
                <Typography variant="caption">
                  {t(`generalStatistics_source_${source.name}`)}
                </Typography>
              </a>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
