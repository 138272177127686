/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from 'react-jss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import FiltersContext from '../../../context/filtering';
import MappingContext from '../../../context/mapping';
import { isTouchDevice } from '../../../constants/utils';
import api from '../../../services/api';
import ProjectionsMapPopup from './map-popup';
import LoaderContext from '../../../context/loading';

/**
 * Provides the map rendering.
 */
export default function ProjectionsMap() {
  const { options, values, setters } = useContext(FiltersContext);
  const { popup, setPopup, setOnHover, setOnClick } = useContext(
    MappingContext
  );
  const theme = useTheme();
  const [shapeLoaded, setShapeLoaded] = useState(false);
  const { setProjectionsLoading } = useContext(LoaderContext);
  const [lastLayerStyle, setLastLayerStyle] = useState(() => () => ({
    id: 'data',
    type: 'fill',
    paint: {
      'fill-color': {
        property: 'value',
        stops: [[-50, '#002033']],
      },
      'fill-opacity': 0,
    },
  }));

  /**
   * This function styles the map.
   * @param {Shape max value} maxValue
   */
  const dataLayer = (maxValue, dataType, loadState) => {
    if (!loadState) return lastLayerStyle(maxValue);

    let newStyle = null;

    if (
      dataType === 'casesEvolutionRate' ||
      dataType === 'deathsEvolutionRate'
    ) {
      newStyle = () => ({
        id: 'data',
        type: 'fill',
        paint: {
          'fill-color': {
            property: 'value',
            stops: [
              theme.mapRatePainting.colors[0],
              theme.mapRatePainting.colors[1],
              theme.mapRatePainting.colors[2],
              theme.mapRatePainting.colors[3],
              theme.mapRatePainting.colors[4],
              theme.mapRatePainting.colors[5],
            ],
          },
          'fill-opacity': theme.mapRatePainting.mapOpacity,
          'fill-outline-color': theme.colorMapSeparator,
        },
      });
    } else {
      newStyle = (biggest) => ({
        id: 'data',
        type: 'fill',
        paint: {
          'fill-color': {
            property: 'value',
            stops: [
              [0, theme.mapDefaultPainting.colors[0]],
              [(0.2 * biggest) / 8, theme.mapDefaultPainting.colors[1]],
              [(0.5 * biggest) / 8, theme.mapDefaultPainting.colors[2]],
              [(0.9 * biggest) / 8, theme.mapDefaultPainting.colors[3]],
              [(1 * biggest) / 8, theme.mapDefaultPainting.colors[4]],
              [(2.5 * biggest) / 8, theme.mapDefaultPainting.colors[5]],
              [(3 * biggest) / 8, theme.mapDefaultPainting.colors[6]],
              [(4 * biggest) / 8, theme.mapDefaultPainting.colors[7]],
              [biggest, theme.mapDefaultPainting.colors[8]],
            ],
          },
          'fill-opacity': theme.mapDefaultPainting.mapOpacity,
          'fill-outline-color': theme.colorMapSeparator,
        },
      });
    }

    setLastLayerStyle(() => newStyle);
    return newStyle(maxValue);
  };

  /**
   * This function loads the map shapes.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (values.territory && options.projectionsDates) {
      setProjectionsLoading((rest) => ({ ...rest, map: true }));
      setShapeLoaded(false);

      api
        .get('/api/projections/shape', {
          params: {
            dataType: values.projectionsDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            scenario: values.scenario,
            day: options.projectionsDates.dates[values.projectionsDate],
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setters.setShape(data.geojson);
            setters.setBiggestValue(data.max);
            setProjectionsLoading((rest) => ({ ...rest, map: false }));
            setShapeLoaded(true);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    values.projectionsDataType,
    values.territory,
    values.scale,
    values.scenario,
    options.projectionsDates,
    values.projectionsDate,
  ]);

  /**
   * This function enter inside a territory without broke the autocomplete logic.
   */
  const enterTerritory = (feature) => {
    if (feature && options.territories && setters) {
      const index = options.territories.findIndex(
        (element) => element.type === values.scale
      );
      const newTerritory = {
        code: feature.properties.code,
        name: feature.properties.name,
        type: values.scale,
      };

      if (index >= 0) {
        if (
          !options.territories.find(
            (element) => element.code === feature.properties.code
          )
        ) {
          options.territories.splice(index, 0, newTerritory);
        }
        setters.setTerritoriesOptions(options.territories);
      } else {
        setters.setTerritoriesOptions([newTerritory, ...options.territories]);
      }

      setters.setTerritory(newTerritory);
    }
  };

  /**
   * This function pull the territory without broke the autocomplete logic.
   */
  const pullTerritory = () => {
    if (values.territoryHistory && values.territoryHistory.length > 1) {
      const lastTerritory =
        values.territoryHistory[values.territoryHistory.length - 2];

      if (lastTerritory && options.territories && setters) {
        const index = options.territories.findIndex(
          (element) => element.type === values.scale
        );
        const newTerritory = { ...lastTerritory };

        if (index >= 0) {
          if (
            !options.territories.find(
              (element) => element.code === lastTerritory.code
            )
          ) {
            options.territories.splice(index, 0, newTerritory);
          }
          setters.setTerritoriesOptions(options.territories);
        } else {
          setters.setTerritoriesOptions([newTerritory, ...options.territories]);
        }

        setters.setTerritory(newTerritory);

        if (newTerritory.shape) setters.setShape(newTerritory.shape);
        if (newTerritory.biggestValue)
          setters.setBiggestValue(newTerritory.biggestValue);
      }
    }
  };

  /**
   * Set the map click.
   */
  useEffect(() => {
    setOnClick(() => (event) => {
      if (event) {
        const { features } = event;
        const feature = features && features.find((f) => f.layer.id === 'data');

        if (isTouchDevice()) {
          if (feature) {
            setPopup(
              <ProjectionsMapPopup
                feature={feature}
                lng={event.lngLat[0]}
                lat={event.lngLat[1]}
                accessTerritory={() => enterTerritory(feature)}
                closePopup={() => setPopup(null)}
              />
            );
          } else {
            if (!popup) {
              pullTerritory();
            }
            setPopup(null);
          }
        } else if (feature) {
          enterTerritory(feature);
          setPopup(null);
        } else {
          pullTerritory();
          setPopup(null);
        }
      }
    });
  }, [popup, values.scale]);

  /**
   * Set the function that the mouse pass above the map.
   * @param {Mouse hover} event
   */
  useEffect(() => {
    setOnHover(() => (event) => {
      if (!isTouchDevice() && event) {
        const { features } = event;
        const feature = features && features.find((f) => f.layer.id === 'data');

        if (event.lngLat) {
          setPopup(
            <ProjectionsMapPopup
              feature={feature}
              lng={event.lngLat[0]}
              lat={event.lngLat[1]}
            />
          );
        }
      }
    });
  }, [setOnHover]);

  const layer = useMemo(
    () => (
      <Layer
        {...dataLayer(
          values.biggestValue,
          values.projectionsDataType,
          shapeLoaded
        )}
        beforeId="settlement-subdivision-label"
      />
    ),
    [shapeLoaded, theme, values.biggestValue]
  );

  return useMemo(
    () => (
      <Source type="geojson" data={values.shape}>
        {layer}
      </Source>
    ),
    [
      values.shape,
      values.biggestValue,
      values.projectionsDataType,
      shapeLoaded,
      theme,
    ]
  );
}
