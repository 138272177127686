/* eslint-disable no-unused-vars */
/* import { Typography } from '@material-ui/core'; */
import React from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Document from '../../../components/document';
import useStyles from './styles';

/**
 * This page shows the data methodology.
 */
export default function MethodData() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <Document>
      <Document.Title>{t('dataMethod:title_0')}</Document.Title>
      <Document.SectionTitle>
        {t('dataMethod:sectionTitle_1')}
      </Document.SectionTitle>
      <Document.Paragraph>{t('dataMethod:paragraph_0')}</Document.Paragraph>
      <Document.SectionTitle>
        {t('dataMethod:sectionTitle_2')}
      </Document.SectionTitle>
      <Document.Paragraph>{t('dataMethod:paragraph_1')}</Document.Paragraph>
      <Document.Paragraph>{t('dataMethod:paragraph_2')}</Document.Paragraph>
      <Document.SectionTitle>
        {t('dataMethod:sectionTitle_3')}
      </Document.SectionTitle>
      <Document.Paragraph>{t('dataMethod:paragraph_3')}</Document.Paragraph>
      <Document.Paragraph>{t('dataMethod:paragraph_4')}</Document.Paragraph>
      <Document.Paragraph>
        {`${t('dataMethod:paragraph_5')} `}
        <Link to="/method-projections" className={classes.link}>
          {t('dataMethod:link_1')}
        </Link>
        .
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('dataMethod:sectionTitle_4')}
      </Document.SectionTitle>
      <Document.Paragraph>{t('dataMethod:paragraph_6')}</Document.Paragraph>
      <Document.SectionTitle>
        {t('dataMethod:sectionTitle_5')}
      </Document.SectionTitle>
      <Document.Paragraph>{t('dataMethod:paragraph_7')}</Document.Paragraph>
      <Document.Paragraph>{t('dataMethod:paragraph_8')}</Document.Paragraph>
    </Document>
  );
}
