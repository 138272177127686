import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import BarLegend from '../../../components/bar-legend';
import RateLegend from '../../../components/rate-legend';
import { projectionsDataTypes } from '../../../constants/general';
import FiltersContext from '../../../context/filtering';

/**
 * An wrapper to build the legend to cases page.
 */
export default function ProjectionsLegend() {
  const {
    values: { projectionsDataType, biggestValue },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const theme = useTheme();

  if (
    projectionsDataType === 'casesEvolutionRate' ||
    projectionsDataType === 'deathsEvolutionRate'
  ) {
    return (
      <RateLegend
        colors={[
          theme.mapRatePainting.colors[0],
          theme.mapRatePainting.colors[1],
          theme.mapRatePainting.colors[2],
          theme.mapRatePainting.colors[3],
          theme.mapRatePainting.colors[4],
          theme.mapRatePainting.colors[5],
        ]}
        title={t(projectionsDataTypes[projectionsDataType].translation)}
        texts={[
          t('casesRateLegend_decrease'),
          t('casesRateLegend_stable'),
          t('casesRateLegend_increase'),
        ]}
      />
    );
  }

  return (
    <BarLegend
      colors={[
        theme.mapDefaultPainting.colors[8],
        theme.mapDefaultPainting.colors[7],
        theme.mapDefaultPainting.colors[6],
        theme.mapDefaultPainting.colors[5],
        theme.mapDefaultPainting.colors[4],
        theme.mapDefaultPainting.colors[3],
        theme.mapDefaultPainting.colors[2],
        theme.mapDefaultPainting.colors[1],
        theme.mapDefaultPainting.colors[0],
      ]}
      title={t('barLegend_title', {
        dataType: t(
          projectionsDataTypes[projectionsDataType].translation
        ).toLowerCase(),
      })}
      minValue="0"
      maxValue={
        biggestValue < 0
          ? 1
          : t('general_number', {
              value: biggestValue,
            })
      }
      breaks={[0, 0.2, 0.5, 0.9, 1, 2.5, 3, 4, 8]}
    />
  );
}
