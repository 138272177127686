export const availableMaps = {
  cases: {
    url: '/cases',
    translation: 'header_casesPageButton',
  },
  /* projections: {
    url: '/projections',
    translation: 'header_projectionsPageButton',
  }, */
  vaccination: {
    url: '/vaccination',
    translation: 'header_vaccinationPageButton',
  },
  /* isolation: {
    url: '/isolation',
    translation: 'header_isolationPageButton',
  },
  indigenous: {
    url: '/indigenous',
    translation: 'header_indigenousPageButton',
  }, */
};

export const defaultOptions = {
  territory: { code: 0, name: 'Brasil', type: 'country' },
  scale: 'state',
  casesDataType: 'cases',
  vaccinationDataType: 'vaccinatedDose1',
  scenario: 1,
  projectionsDataType: 'casesPerDay',
  mapViewport: {
    latitude: -14.235004,
    longitude: -51.92528,
    zoom: 2,
  },
};

export const scenarioOptions = {
  1: {
    translation: 'scenarios_1',
  },
  2: {
    translation: 'scenarios_2',
  },
  3: {
    translation: 'scenarios_3',
  },
};

export const casesDataTypes = {
  casesPerDay: {
    translation: 'dataType_casesPerDay',
  },
  cases: {
    translation: 'dataType_cases',
  },
  casesPerPopulation: {
    translation: 'dataType_casesPerPopulation',
  },
  casesEvolutionRate: {
    translation: 'dataType_casesEvolutionRate',
  },
  deathsPerDay: {
    translation: 'dataType_deathsPerDay',
  },
  deaths: {
    translation: 'dataType_deaths',
  },
  deathsPerPopulation: {
    translation: 'dataType_deathsPerPopulation',
  },
  deathsEvolutionRate: {
    translation: 'dataType_deathsEvolutionRate',
  },
  rt: {
    translation: 'dataType_rt',
  },
};

export const vaccinationDataTypes = {
  vaccinatedDose1: {
    translation: 'dataType_vaccinatedDose1',
  },
  vaccinatedDose1PerDay: {
    translation: 'dataType_vaccinatedDose1PerDay',
  },
  vaccinatedDose1PerPopulation: {
    translation: 'dataType_vaccinatedDose1PerPopulation',
  },
  vaccinatedDose2: {
    translation: 'dataType_vaccinatedDose2',
  },
  vaccinatedDose2PerDay: {
    translation: 'dataType_vaccinatedDose2PerDay',
  },
  vaccinatedDose2PerPopulation: {
    translation: 'dataType_vaccinatedDose2PerPopulation',
  },
};

export const projectionsDataTypes = {
  casesPerDay: {
    translation: 'dataType_casesPerDay',
  },
  cases: {
    translation: 'dataType_cases',
  },
  casesPerPopulation: {
    translation: 'dataType_casesPerPopulation',
  },
  casesEvolutionRate: {
    translation: 'dataType_casesEvolutionRate',
  },
  deathsPerDay: {
    translation: 'dataType_deathsPerDay',
  },
  deaths: {
    translation: 'dataType_deaths',
  },
  deathsPerPopulation: {
    translation: 'dataType_deathsPerPopulation',
  },
  deathsEvolutionRate: {
    translation: 'dataType_deathsEvolutionRate',
  },
  nurseryDemand: {
    translation: 'dataType_nurseryDemand',
  },
  utiDemand: {
    translation: 'dataType_utiDemand',
  },
};

export const vaccineNames = {
  86: 'Coronavac',
  87: 'BioNTech/Pfizer',
  81: 'Não declarado',
  85: 'Astrazeneca/Oxford',
};
