import { createUseStyles } from 'react-jss';
import {
  menuHeightMobile,
  menuHeightDesktop,
  filtersFooterHeightMobile,
  filtersHeightDesktop,
  statisticsBarWidthDesktop,
} from '../../constants/sizes';
import { defaultBackgroundTransition } from '../../constants/styles';

const padding = '10px 20px';
const borderRadius = '20px';

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.colorBackground,
    display: 'flex',
    position: 'fixed',
    top: menuHeightDesktop,
    bottom: 0,
    left: 0,
    right: 0,

    '@media (max-width: 599px)': {
      flexFlow: 'row wrap',
      overflow: 'auto',
      width: '100vw',
    },
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
  filters: {
    backgroundColor: theme.colorBackground,
    transition: defaultBackgroundTransition,
    padding,
    height: filtersHeightDesktop,
    zIndex: '200',
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.15)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 0,
    paddingBottom: 5,

    '@media (max-width: 599px)': {
      borderRadius: `0px 0px ${borderRadius} ${borderRadius}`,
      padding,
      height: 'auto',
      position: 'fixed',
      display: 'flex',
      flexFlow: 'column',
      top: menuHeightMobile,
      left: 0,
      right: 0,
    },

    '@media (max-width: 999px)': {
      padding,
      height: 'auto',
      display: 'flex',
      flexFlow: 'column',
    },
  },
  filtersItens: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',

    '@media (max-width: 999px)': {
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      paddingTop: 5,

      '&::after': {
        content: "''",
        height: 15,
      },
    },
  },
  filterItem: {
    marginRight: '10px',
    width: '100%',

    '@media (max-width: 999px)': {
      marginBottom: '15px',
      marginRight: '0px',
    },
  },
  filterFooter: {
    display: 'none',

    '@media (max-width: 999px)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: filtersFooterHeightMobile,
      padding: '5px 0px',

      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        marginTop: -52,
        marginLeft: -20,
        backgroundColor: theme.colorSeparator,
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 50,
        marginLeft: -20,
      },
      '&:focus': {
        outline: 'none',
      },
    },
  },
  map: {
    height: '100%',

    '@media (max-width: 599px)': {
      height: `calc(100vh - ${50 * 2 + menuHeightMobile}px)`,
      marginBottom: 50 * 2 + menuHeightMobile,
    },
  },
  statisticsBar: {
    backgroundColor: theme.colorBackground,
    transition: defaultBackgroundTransition,
    overflow: 'auto',
    width: statisticsBarWidthDesktop,
    zIndex: '300',
    boxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.15)',
    padding,
    paddingTop: 15,
    position: 'relative',

    '@media (max-width: 599px)': {
      borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
      width: '100%',
      zIndex: '100',
      marginTop: -(menuHeightMobile + filtersFooterHeightMobile + 100),
    },
  },
  statisticsHeader: {
    display: 'none',

    '&:focus': {
      outline: 'none',
    },
    '@media (max-width: 599px)': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px',
      marginBottom: 20,

      '&::after': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 1,
        marginTop: 52,
        marginLeft: -20,
        backgroundColor: theme.colorSeparator,
      },
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 55,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      },
    },
  },
  statisticItem: {
    paddingTop: padding,
    marginBottom: 30,

    '&::after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: 1,
      marginTop: 15,
      marginLeft: -20,
      backgroundColor: theme.colorSeparator,
    },
    '&:last-child': {
      '&::after': {
        display: 'none',
      },
    },
  },
  popup: {
    '& .mapboxgl-popup-content': {
      backgroundColor: theme.colorBackground,
      borderRadius: 10,
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)',
      color: theme.colorText,
      minWidth: 120,

      '& .mapboxgl-popup-close-button': {
        marginRight: 5,
      },
    },
    '& .mapboxgl-popup-tip': {
      borderTopColor: theme.colorBackground,
      borderBottomColor: theme.colorBackground,
    },
  },
  popupSeparator: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colorSeparator,
  },
  popupButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '&:focus': {
      outline: 'none',
    },
  },
  centeredLoader: {
    position: 'fixed',
    zIndex: 1000,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    transition: 'opacity 0.2s ease',

    '&::after': {
      content: "''",
      boxShadow: `0px 0px 100px 30px #00000040`,
      position: 'relative',
      zIndex: -1,
      left: '-50%',
    },
  },
}));

export default useStyles;
