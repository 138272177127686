import { useEffect, useState } from 'react';
import { defaultOptions } from '../constants/general';

/**
 * This hook updates the territoryhistory and return the current territory history.
 * @param {A list containing scale hierarchy} scalesHierarchy
 * @param {The current selected territory} territory
 * @param {The current selected scale} scale
 * @param {The current selected shape} shape
 * @param {The current shape biggestValue} biggestValue
 */
export default function useTerritoryHistory(
  scalesHierarchy,
  territory,
  scale,
  shape,
  biggestValue
) {
  const [territoryHistory, setTerritoryHitory] = useState([
    defaultOptions.territory,
  ]);

  /**
   * This useEffect calculate a new territory history list.
   */
  useEffect(() => {
    if (scalesHierarchy) {
      const hierarchyAmountAux = {};
      const hierarchyPosition = {};

      scalesHierarchy.forEach((hierarchyEl) => {
        if (!hierarchyAmountAux[hierarchyEl.position])
          hierarchyAmountAux[hierarchyEl.position] = 0;
        hierarchyAmountAux[hierarchyEl.position] += 1;
        hierarchyPosition[hierarchyEl.type] = hierarchyEl.position;
      });

      const territoryHistoryAux = territoryHistory;

      const foundIndex = territoryHistoryAux.findIndex(
        (element) =>
          element.type === territory.type ||
          hierarchyPosition[element.type] === hierarchyPosition[territory.type]
      );

      const territoryAux = {
        ...territory,
        defaultScale: scale,
        shape,
        biggestValue,
      };

      if (foundIndex > -1) {
        territoryHistoryAux.splice(
          foundIndex,
          Object.keys(hierarchyAmountAux).length,
          territoryAux
        );
      } else {
        const territoryPosition = hierarchyPosition[territoryAux.type];

        let foundMinorIndex = -1;

        for (
          let i = 0;
          i < territoryHistoryAux.length && foundMinorIndex === -1;
          i += 1
        ) {
          const iPosition = hierarchyPosition[territoryHistoryAux[i].type];

          if (territoryPosition < iPosition) {
            foundMinorIndex = i;
          }
        }

        if (foundMinorIndex > -1) {
          territoryHistoryAux.splice(
            foundMinorIndex,
            Object.keys(hierarchyAmountAux).length,
            territoryAux
          );
        } else {
          territoryHistoryAux.push(territoryAux);
        }
      }

      setTerritoryHitory(territoryHistoryAux);
    }
  }, [scalesHierarchy, territory, scale, shape, biggestValue]);

  if (!(territory && scalesHierarchy)) return [defaultOptions.territory];

  return territoryHistory;
}
