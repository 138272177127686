/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);
/**
 * This component is a stylized chart to display series data.
 * @param {Object with two arrays: categories and values} data
 */
export default function SeriesChart({ data, color }) {
  SeriesChart.defaultProps = {
    data: undefined,
    color: undefined,
  };

  SeriesChart.propTypes = {
    data: PropTypes.shape(),
    color: PropTypes.string,
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const { t } = useTranslation();

  /**
   * This useEffect sets zoom according by datatype
   */
  const [chartObj, setChartObj] = useState();
  useEffect(() => {
    if (chartObj) {
      if (chartObj.series) {
        const series = chartObj.series[0];
        if (
          data.dataType === 'casesEvolutionRate' ||
          data.dataType === 'deathsEvolutionRate'
        ) {
          chartObj.xAxis[0].zoom(
            series.xData[60],
            series.xData[data.x.length - 1]
          );
        } else {
          chartObj.xAxis[0].zoom(
            series.xData[0],
            series.xData[data.x.length - 1]
          );
        }
      }
    }
  });

  /**
   * This useEffect add or remove line on y = 1 according by R(t)
   */
  useEffect(() => {
    if (chartObj) {
      if (data.dataType === 'rt') {
        chartObj.yAxis[0].addPlotLine({
          color: theme.textColor,
          width: 2,
          value: 1,
          id: 'plot-line-rt',
        });
      } else {
        chartObj.yAxis[0].removePlotLine('plot-line-rt');
      }
    }
  }, [data]);

  const options = {
    title: {
      text: `${t('seriesChart_preTitle')} ${t(
        `dataType_${data.dataType}`
      ).toLowerCase()}`,
      align: 'left',
      style: {
        color: theme.colorText,
        fontFamily: 'roboto',
        fontWeight: 400,
        fontSize: 16,
      },
    },
    series: data.avg
      ? [
          {
            name: t(`dataType_${data.dataType}`),
            data: data.y,
            type: 'column',
          },
          {
            name: t('seriesChart_mobileAvg'),
            data: data.avg,
            color: theme.colorText,
            type: 'line',
          },
        ]
      : [
          {
            name: t(`dataType_${data.dataType}`),
            data: data.y,
            type: 'line',
          },
        ],
    chart: {
      backgroundColor: 'transparent',
      padding: 0,
      height: 300,
      spacing: [10, 10, 15, 0],
      zoomType: 'x',
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        margin: 0,
        labels: {
          style: {
            color: theme.colorText,
          },
        },
        gridLineColor: theme.colorSeparator,
      },
    ],
    xAxis: [
      {
        tickWidth: 1,
        tickInterval: 150,
        labels: {
          formatter: function () {
            return t('general_date', { date: new Date(this.value) });
          },

          style: {
            color: theme.colorText,
          },
        },
        title: {
          text: t('seriesChart_xTitle'),
          enabled: false,
        },
        categories: data.x,
        gridLineColor: theme.colorSeparator,
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: function () {
        if (
          data.dataType === 'casesEvolutionRate' ||
          data.dataType === 'deathsEvolutionRate' ||
          data.dataType === 'vaccinatedDose1PerPopulation' ||
          data.dataType === 'vaccinatedDose2PerPopulation'
        ) {
          return `<b>${t('general_date', {
            date: new Date(this.point.category),
          })}</b> </br>
            <tr><td style="color: ${this.series.color}">${
            this.series.name
          }: </td>
              <td style="text-align: right"><b>${t('general_number', {
                value: this.point.y,
              })}%
            </b></td></tr>`;
        }

        return `<b>${t('general_date', {
          date: new Date(this.point.category),
        })}</b> </br>
          <tr><td style="color: ${this.series.color}">${this.series.name}: </td>
            <td style="text-align: right"><b>${t('general_number', {
              value: this.point.y,
            })}
          </b></td></tr>`;
      },
    },
    colors: [color],
    legend: {
      itemStyle: {
        color: theme.colorText,
      },
    },
    navigation: {
      menuStyle: {
        background: theme.colorBackground,
        border: 'none',
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      },
      menuItemStyle: { color: theme.colorText },
      menuItemHoverStyle: { background: color },
    },
    lang: {
      contextButtonTitle: 'Exportar gráfico',
    },
    plotOptions: {
      column: {
        borderWidth: 0,
      },
    },
    exporting: {
      chartOptions: {
        chart: {
          style: {
            backgroundColor: theme.colorBackground,
          },
        },
      },
      menuItemDefinitions: {
        downloadPNG: { text: `${t('charts_printButton')} .png` },
        downloadJPEG: { text: `${t('charts_printButton')} .jpeg` },
        downloadPDF: { text: `${t('charts_printButton')} .pdf` },
        downloadSVG: { text: `${t('charts_printButton')} .svg` },
      },
      buttons: {
        contextButton: {
          symbolFill: theme.colorText,
          symbolStroke: theme.colorText,
          theme: {
            fill: 'transparent',
            states: {
              hover: {
                fill: theme.colorSeparator,
              },
              select: {
                fill: theme.colorSeparator,
              },
            },
          },
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      callback={(chart) => {
        setChartObj(chart);
      }}
    />
  );
}
