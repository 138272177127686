export const lightTheme = {
  colorPrimary: '#F62A4F',
  colorSecondary: '#9A2E55',
  colorTertiary: '#35D1AF',
  colorQuaternary: '#005365',
  colorText: '#555555',
  colorBackground: '#FFFFFF',
  colorSecondaryBackground: '#F5F5F5',
  colorTertiaryBackground: '#FFFFFF',
  colorSeparator: '#E6E6E6',
  colorBorder: '#BDBDBD',
  colorMapSeparator: '#bcbdbd',
  colorMapBackground: '#E3E3E3',
  colorSecondaryText: '#adadad',
  colorFooterBackground: '#FAFAFA',
  mapDefaultPainting: {
    mapOpacity: 0.9,
    colors: [
      '#EAF0EA',
      '#FFCCBB',
      '#FB9F9C',
      '#FD8273',
      '#FF5C4B',
      '#FF0136',
      '#CC0037',
      '#A50030',
      '#650025',
    ],
  },
  mapRatePainting: {
    mapOpacity: 0.9,
    colors: ['#00376A', '#0070AE', '#FFC942', '#FFC942', '#FF0136', '#650025'],
  },
  mapVaccinationPainting: {
    mapOpacity: 0.9,
    colors: [
      '#f7fcf0',
      '#e0f3db',
      '#ccebc5',
      '#a8ddb5',
      '#81D39D',
      '#57C28F',
      '#359572',
      '#297E65',
      '#0F584B',
    ],
  },
  semiCircleVaccinationPainting: {
    colors: ['#4eb3d3', '#2b8cbe', '#0868ac', '#084081', '#35D1AF', '#005365'],
  },
  pieVaccinationPainting: {
    colors: ['#35D1AF', '#084081', '#005365', '#0868ac', '#2b8cbe', '#4eb3d3'],
  },
};

export const darkTheme = {
  colorPrimary: '#F62A4F',
  colorSecondary: '#9A2E55',
  colorTertiary: '#35D1AF',
  colorQuaternary: '#005365',
  colorText: '#FFFFFF',
  colorBackground: '#1C1C1C',
  colorSecondaryBackground: '#303030',
  colorTertiaryBackground: '#424242',
  colorSeparator: '#616161',
  colorBorder: '#BDBDBD',
  colorMapSeparator: '#5e5d5d',
  colorMapBackground: '#242424',
  colorSecondaryText: '#adadad',
  colorFooterBackground: '#1f1f1f',
  mapDefaultPainting: {
    mapOpacity: 0.7,
    colors: [
      '#EAF0EA',
      '#FFCCBB',
      '#FB9F9C',
      '#FD8273',
      '#FF5C4B',
      '#FF0136',
      '#CC0037',
      '#A50030',
      '#650025',
    ],
  },
  mapRatePainting: {
    mapOpacity: 0.7,
    colors: ['#00376A', '#0070AE', '#FFC942', '#FFC942', '#FF0136', '#650025'],
  },
  mapVaccinationPainting: {
    mapOpacity: 0.7,
    colors: [
      '#f7fcf0',
      '#e0f3db',
      '#ccebc5',
      '#a8ddb5',
      '#81D39D',
      '#57C28F',
      '#359572',
      '#297E65',
      '#0F584B',
    ],
  },
  semiCircleVaccinationPainting: {
    colors: ['#084081', '#0868ac', '#4eb3d3', '#2b8cbe', '#35D1AF', '#005365'],
  },
  pieVaccinationPainting: {
    colors: ['#35D1AF', '#084081', '#005365', '#0868ac', '#2b8cbe', '#4eb3d3'],
  },
};
