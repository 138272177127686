import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as JssThemeProvider } from 'react-jss';
import { lightTheme, darkTheme } from '../constants/themes';

const ThemeContext = createContext({});

/**
 * The ThemeProvider is a wrapper to JssThemeProvider that allows switch themes.
 * */
export function ThemeProvider({ children }) {
  ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const [selectedTheme, setSelectedTheme] = useState(lightTheme);

  /**
   * Load last selected theme.
   */
  useEffect(() => {
    const savedTheme = localStorage.getItem('@geocovid/theme');
    setSelectedTheme(savedTheme === 'dark' ? darkTheme : lightTheme);
  }, []);

  /**
   * Switch between light and dark themes.
   */
  function switchTheme() {
    localStorage.setItem(
      '@geocovid/theme',
      selectedTheme === lightTheme ? 'dark' : 'light'
    );
    setSelectedTheme(selectedTheme === lightTheme ? darkTheme : lightTheme);
  }

  return (
    <ThemeContext.Provider value={{ selectedTheme, switchTheme }}>
      <JssThemeProvider theme={selectedTheme}>{children}</JssThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
