import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  column: {
    display: 'flex',
    flexFlow: 'column',
  },
  row: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  href: {
    color: theme.colorSecondaryText,
    textDecoration: 'none',
    transition: 'color 0.2s ease',
    '&:focus': {
      outline: `none`,
    },

    '&:hover': {
      color: ({ secondColor }) => secondColor,
    },
  },
}));

export default useStyles;
