/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * This component is a stylized chart to display projections data.
 * @param {Object with two arrays: categories and values} data
 */
export default function ProjectionsChart({ data }) {
  ProjectionsChart.defaultProps = {
    data: undefined,
  };

  ProjectionsChart.propTypes = {
    data: PropTypes.shape(),
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const { t } = useTranslation();

  const [chartObj, setChartObj] = useState();
  useEffect(() => {
    if (chartObj) {
      if (chartObj.series) {
        const series = chartObj.series[0];
        if (
          data.dataType === 'casesEvolutionRate' ||
          data.dataType === 'deathsEvolutionRate'
        ) {
          chartObj.xAxis[0].zoom(
            series.xData[60],
            series.xData[data.x.length - 1]
          );
        } else {
          chartObj.xAxis[0].zoom(
            series.xData[0],
            series.xData[data.x.length - 1]
          );
        }
      }
    }
  });

  const yReal = {
    name: t(`dataType_${data.dataType}`),
    data: data.yReal,
    type: data.avg ? 'column' : 'line',
    showInLegend: !(
      data.dataType === 'nurseryDemand' || data.dataType === 'utiDemand'
    ),
  };

  const yProjectionsPast = {
    name: t('projectionsChart_projectionPast'),
    data: data.yProjectionPast,
    color: theme.colorText,
    type: data.avg ? 'column' : 'line',
    dashStyle: data.avg ? 'solid' : 'dash',
  };

  const yProjectionsFuture = {
    name: t('projectionsChart_projectionFuture'),
    data: data.yProjectionFuture,
    color: theme.colorSecondary,
    type: data.avg ? 'column' : 'line',
  };

  const yAvg = {
    name: t('projectionsChart_mobileAvg'),
    data: data.avg,
    color: theme.colorText,
    type: 'line',
    zIndex: 1,
    lineWidth: 2,
    showInLegend: true,
  };

  const yError = {
    name: t('projectionsChart_error'),
    data: data.error,
    type: 'arearange',
    lineWidth: 0,
    linkedTo: ':previous',
    color: theme.colorSecondary,
    fillOpacity: 0.3,
    showInLegend: false,
    zIndex: 0,
    marker: {
      enabled: false,
    },
  };
  const options = {
    title: {
      text: `${t('projectionsChart_preTitle')} ${t(
        `dataType_${data.dataType}`
      ).toLowerCase()}`,
      align: 'left',
      style: {
        color: theme.colorText,
        fontFamily: 'roboto',
        fontWeight: 400,
        fontSize: 16,
      },
    },
    chart: {
      backgroundColor: 'transparent',
      padding: 0,
      height: 300,
      spacing: [10, 10, 15, 0],
      zoomType: 'x',
    },
    xAxis: [
      {
        tickWidth: 1,
        tickInterval: 150,
        labels: {
          formatter() {
            return t('general_date', { date: new Date(this.value) });
          },
          style: {
            color: theme.colorText,
          },
        },
        categories: data.x,
        gridLineColor: theme.colorSeparator,
      },
    ],
    series: data.avg
      ? [yReal, yProjectionsFuture, yAvg]
      : data.error
      ? [yReal, yProjectionsPast, yProjectionsFuture, yError]
      : [yReal, yProjectionsPast, yProjectionsFuture],
    yAxis: [
      {
        title: {
          text: '',
        },
        margin: 0,
        labels: {
          style: {
            color: theme.colorText,
          },
        },
        gridLineColor: theme.colorSeparator,
      },
    ],
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,

      formatter() {
        let html = `${t('general_date', {
          date: new Date(this.x),
        })}</br>`;

        this.points.forEach((p) => {
          if (p.series.name === t('projectionsChart_error')) {
            html += `
            <tr>
              <td>${p.series.name}: </td>
              <td style="text-align: right">
                <b>${t('general_number', {
                  value: p.point.options.low,
                })}</b> ~ 
                <b>${t('general_number', {
                  value: p.point.options.high,
                })} 
                </b>
              </td>
            </tr>
            </br>`;
          } else {
            html += `
            <tr>
              <td>${p.series.name}: </td>
              <td style="text-align: right">
                <b>${t('general_number', {
                  value: p.y,
                })} 
                </b>
              </td>
            </tr>
            </br>`;
          }
        });
        return html;
      },
    },
    colors: [theme.colorPrimary],
    legend: {
      itemStyle: {
        color: theme.colorText,
      },
    },
    navigation: {
      menuStyle: {
        background: theme.colorBackground,
        border: 'none',
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      },
      menuItemStyle: { color: theme.colorText },
      menuItemHoverStyle: { background: theme.colorPrimary },
    },
    lang: {
      contextButtonTitle: 'Exportar gráfico',
    },
    plotOptions: {
      column: {
        borderWidth: 0,
      },
    },
    exporting: {
      chartOptions: {
        chart: {
          style: {
            backgroundColor: theme.colorBackground,
          },
        },
      },
      menuItemDefinitions: {
        downloadPNG: { text: `${t('charts_printButton')} .png` },
        downloadJPEG: { text: `${t('charts_printButton')} .jpeg` },
        downloadPDF: { text: `${t('charts_printButton')} .pdf` },
        downloadSVG: { text: `${t('charts_printButton')} .svg` },
      },
      buttons: {
        contextButton: {
          symbolFill: theme.colorText,
          symbolStroke: theme.colorText,
          theme: {
            fill: 'transparent',
            states: {
              hover: {
                fill: theme.colorSeparator,
              },
              select: {
                fill: theme.colorSeparator,
              },
            },
          },
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      callback={(chart) => {
        setChartObj(chart);
      }}
    />
  );
}
