import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

/**
 * This component creates a slider containing the available time period.
 */
function PeriodSlider({
  datesOptions,
  setDate,
  indexDefault,
  firstColor,
  secondColor,
}) {
  PeriodSlider.propTypes = {
    datesOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    setDate: PropTypes.func.isRequired,
    indexDefault: PropTypes.number,
    firstColor: PropTypes.string,
    secondColor: PropTypes.string,
  };

  const theme = useTheme();
  const classes = useStyles({ firstColor, secondColor, theme });
  const [auxValue, setAuxValue] = useState(0);
  const [lastWheeled, setLastWheeled] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  /**
   * Update the aux value to receive a default value.
   */
  useEffect(() => {
    if (datesOptions) {
      if (indexDefault) {
        setAuxValue(indexDefault);
      } else {
        setAuxValue(datesOptions.length - 1);
      }
    }
  }, [datesOptions]);

  /**
   * This useEffect prevents a request explosion on wheeling.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (!firstLoad) {
      setTimeout(() => {
        if (isSubscribed) setDate(auxValue);
      }, 1000);
    } else {
      setFirstLoad(false);
    }

    return () => {
      isSubscribed = false;
    };
  }, [lastWheeled]);

  /**
   * This function changes the current period aux value.
   * @param {*} event
   */
  function onWheel(event) {
    if (event.deltaY > 0) {
      if (auxValue > 0) {
        setAuxValue(auxValue - 1);
        setLastWheeled(Date.now());
      }
    } else if (auxValue < datesOptions.length - 1) {
      setAuxValue(auxValue + 1);
      setLastWheeled(Date.now());
    }
  }

  return useMemo(() => {
    /**
     * The component will be rendered only if there are a options list.
     */
    if (!datesOptions) {
      return null;
    }

    return (
      <div className={classes.container} onWheel={onWheel}>
        <div className={classes.label}>
          {t('map_periodTitle', {
            date: new Date(datesOptions[auxValue]),
          })}
        </div>
        <Slider
          value={auxValue}
          min={0}
          step={1}
          max={datesOptions.length - 1}
          classes={{
            root: classes.slider,
            track: classes.sliderTrack,
            rail: classes.sliderRail,
            thumb: classes.sliderThumb,
          }}
          onChange={(Event, newValue) => setAuxValue(newValue)}
          onChangeCommitted={(Event, newValue) => setDate(newValue)}
        />
      </div>
    );
  }, [t, datesOptions, auxValue, theme, classes]);
}

export default PeriodSlider;
