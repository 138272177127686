import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  iconButton: {
    color: theme.colorText,
  },
  mapsButtonText: {
    color: theme.colorText,
    fontSize: '1rem',
    textTransform: 'none',
  },
  mapsButton: {
    margin: '0px 5px',
    display: 'none',

    '@media (max-width: 899px)': {
      display: 'block',
    },
  },
  collapsibleButtons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',

    '@media (max-width: 899px)': {
      display: 'none',
    },
  },
  verticalSeparator: {
    backgroundColor: theme.colorSeparator,
    marginLeft: 3,
    marginRight: 3,
    width: 1,
    height: 20,
  },
  selectionAlert: {
    position: 'absolute',
    width: 'calc(100% + 14px)',
    height: 5,
    bottom: -14,
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: ({ selectedColor }) => selectedColor,
    pointerEvents: 'none',

    '@media (max-width: 599px)': {
      bottom: -10,
    },
  },
  menuPopOver: {
    '& .MuiPaper-root': {
      backgroundColor: theme.colorTertiaryBackground,
      color: theme.colorText,
    },
  },
}));

export default useStyles;
