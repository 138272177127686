/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { useTheme } from 'react-jss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import highchartsMore from 'highcharts/highcharts-more';
import { vaccineNames } from '../../../constants/general';

highchartsMore(Highcharts);
/**
 * This component is a stylized pie chart to display data.
 * @param {Object with one array} data
 */
export default function PieChart({ data }) {
  PieChart.defaultProps = {
    data: undefined,
  };

  PieChart.propTypes = {
    data: PropTypes.shape(),
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const { t } = useTranslation();

  const options = {
    title: {
      text: `${t(`dataType_${data.dataType}`)} ${t('pieChart_posTitle')}`,
      align: 'left',
      style: {
        color: theme.colorText,
        fontFamily: 'roboto',
        fontWeight: 400,
        fontSize: 16,
      },
    },
    series: [
      {
        data: data.vaccineNames,
        colors: theme.pieVaccinationPainting.colors,
        type: 'pie',
        showInLegend: true,
      },
    ],
    chart: {
      backgroundColor: 'transparent',
      padding: 0,
      height: 300,
      spacing: [10, 10, 15, 0],
      zoomType: 'x',
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        margin: 0,
        labels: {
          style: {
            color: theme.colorText,
          },
        },
        gridLineColor: theme.colorSeparator,
      },
    ],
    xAxis: [
      {
        tickWidth: 1,
        tickInterval: 150,
        labels: {
          formatter: function () {
            return t('general_date', { date: new Date(this.value) });
          },

          style: {
            color: theme.colorText,
          },
        },
        title: {
          text: t('seriesChart_xTitle'),
          enabled: false,
        },
        categories: data.x,
        gridLineColor: theme.colorSeparator,
      },
    ],
    tooltip: {
      useHTML: true,
      formatter: function () {
        if (vaccineNames[this.point.code]) {
          return (
            `<b>${vaccineNames[this.point.code]}</b><br/>` +
            ` ${t(`dataType_${data.dataType}`)}: <b> ${t('general_number', {
              value: this.point.y,
            })} (${Highcharts.numberFormat(
              Math.abs(this.point.percentage),
              2
            )}%)</b>`
          );
        }
        return (
          `<b>${this.point.name}</b><br/>` +
          ` ${t(`dataType_${data.dataType}`)}: <b> ${t('general_number', {
            value: this.point.y,
          })} (${Highcharts.numberFormat(
            Math.abs(this.point.percentage),
            2
          )}%)</b>`
        );
      },
    },
    colors: theme.colorTertiary,
    legend: {
      itemStyle: {
        color: theme.colorText,
      },
      labelFormatter: function () {
        if (vaccineNames[this.code]) {
          return vaccineNames[this.code];
        }
        return this.name;
      },
    },
    navigation: {
      menuStyle: {
        background: theme.colorBackground,
        border: 'none',
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      },
      menuItemStyle: { color: theme.colorText },
      menuItemHoverStyle: { background: theme.colorTertiary },
    },
    lang: {
      contextButtonTitle: 'Exportar gráfico',
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        size: '100%',
      },
    },
    exporting: {
      chartOptions: {
        chart: {
          style: {
            backgroundColor: theme.colorBackground,
          },
        },
      },
      menuItemDefinitions: {
        downloadPNG: { text: `${t('charts_printButton')} .png` },
        downloadJPEG: { text: `${t('charts_printButton')} .jpeg` },
        downloadPDF: { text: `${t('charts_printButton')} .pdf` },
        downloadSVG: { text: `${t('charts_printButton')} .svg` },
      },
      buttons: {
        contextButton: {
          symbolFill: theme.colorText,
          symbolStroke: theme.colorText,
          theme: {
            fill: 'transparent',
            states: {
              hover: {
                fill: theme.colorSeparator,
              },
              select: {
                fill: theme.colorSeparator,
              },
            },
          },
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
