import { useMediaQuery } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import FilterSelect from '../../../components/filter-select';
import PeriodSlider from '../../../components/period-slider';
import TerritoriesAutocomplete from '../../../components/territories-autocomplete';
import FiltersContext from '../../../context/filtering';
import useStyles from '../styles';

/**
 * Provides the data and components of the cases filters.
 */
export default function VaccinationFilters() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isBreak = useMediaQuery('(max-width:999px)');
  const { options, values, setters } = useContext(FiltersContext);
  const { t } = useTranslation();

  /**
   * Render the scalesFilter component using memo.
   */
  const scalesFilter = useMemo(
    () => (
      <FilterSelect
        filterName={t('scales_title')}
        options={options.scales}
        value={values.scale}
        setValue={setters.setScale}
        firstColor={theme.colorTertiary}
        secondColor={theme.colorQuaternary}
      />
    ),
    [t, options.scales, values.scale]
  );

  /**
   *  Render the dataType filter component using memo.
   */
  const dataTypeFilter = useMemo(
    () => (
      <FilterSelect
        filterName={t('dataType_title')}
        options={options.vaccinationDataTypes}
        value={values.vaccinationDataType}
        setValue={setters.setVaccinationDataType}
        firstColor={theme.colorTertiary}
        secondColor={theme.colorQuaternary}
      />
    ),
    [t, options.vaccinationDataTypes, values.vaccinationDataType]
  );

  return useMemo(
    () => (
      <>
        <div className={classes.filterItem}>
          <TerritoriesAutocomplete
            filterName="Território"
            firstColor={theme.colorTertiary}
            secondColor={theme.colorQuaternary}
          />
        </div>
        <div className={classes.filterItem}>{scalesFilter}</div>
        <div className={classes.filterItem}>{dataTypeFilter}</div>

        {options.vaccinationDates && (
          <div
            style={isBreak ? { minWidth: 'none' } : { minWidth: '120px' }}
            className={classes.filterItem}
          >
            <PeriodSlider
              datesOptions={options.vaccinationDates}
              setDate={setters.setVaccinationDate}
              firstColor={theme.colorTertiary}
              secondColor={theme.colorQuaternary}
            />
          </div>
        )}
      </>
    ),
    [classes, scalesFilter, dataTypeFilter, isBreak]
  );
}
