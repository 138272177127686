import { useEffect, useState } from 'react';

/**
 * A function hook to select the last date.
 * @param {A date array} dateObj
 */
export default function useProjectionsDate(dateObj) {
  const [date, setDate] = useState(dateObj ? dateObj.indexDefault : 0);

  /**
   * Set new date value if it's updated
   */
  useEffect(() => {
    if (dateObj) {
      setDate(dateObj.indexDefault);
    }
  }, [dateObj]);

  return [date, setDate];
}
