import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ExpandMore, Translate } from '@material-ui/icons';
import useStyles from './styles';

const availableLanguages = i18next.options.resources;

/**
 * The TranslationsButton component create a responsive button containing selections for platform translations.
 */
function TranslationsButton() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  const [translationMenuAnchor, setTranslationMenuAnchor] = useState(null);

  /**
   * This function opens the translation menu.
   * @param {Click} event
   */
  function handleTranslationMenuClick(event) {
    setTranslationMenuAnchor(event.currentTarget);
  }

  /**
   * This function closes the translation menu
   */
  function handleTranslationMenuClose() {
    setTranslationMenuAnchor(null);
  }

  return (
    <>
      <div className={classes.translationExtendedButton}>
        <Button
          aria-owns={translationMenuAnchor ? 'translation-menu' : undefined}
          aria-haspopup="true"
          onClick={handleTranslationMenuClick}
          startIcon={<Translate className={classes.iconButton} />}
          endIcon={<ExpandMore className={classes.iconButton} />}
        >
          <Typography
            className={classes.translationButtonText}
            style={{ fontWeight: 300 }}
          >
            {t('general_languageName')}
          </Typography>
        </Button>
      </div>
      <div className={classes.translationMinorButton}>
        <IconButton
          aria-owns={translationMenuAnchor ? 'translation-menu' : undefined}
          aria-haspopup="true"
          aria-label="language"
          onClick={handleTranslationMenuClick}
        >
          <Translate className={classes.iconButton} />
        </IconButton>
      </div>
      <Menu
        id="translation-menu"
        anchorEl={translationMenuAnchor}
        open={Boolean(translationMenuAnchor)}
        onClose={handleTranslationMenuClose}
        PopoverClasses={{ root: classes.menuPopOver }}
      >
        {Object.keys(availableLanguages).map((language) => (
          <MenuItem
            key={language}
            onClick={() => {
              i18next.changeLanguage(language);
              handleTranslationMenuClose();
            }}
          >
            {availableLanguages[language].translation.general_languageName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default TranslationsButton;
