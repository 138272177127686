/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useTheme, createUseStyles } from 'react-jss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

/**
 * This component is a stylized chart to display ranked data.
 * @param {Object with two arrays: categories and values} data
 * @param {Total of pages} totalPages
 * @param {Current page} page
 * @param {Handle change page function} handleChangePage
 */
export default function RankingChart({
  preTitle,
  data,
  totalPages,
  page,
  setRankingPage,
  color,
}) {
  RankingChart.defaultProps = {
    data: undefined,
    totalPages: 0,
  };

  RankingChart.propTypes = {
    preTitle: PropTypes.string.isRequired,
    data: PropTypes.shape(),
    totalPages: PropTypes.number,
    page: PropTypes.number.isRequired,
    setRankingPage: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
  };

  const useStyles = createUseStyles((theme) => ({
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiPaginationItem-root': {
        color: theme.colorText,
      },
      '& .MuiPaginationItem-page.Mui-selected': {
        backgroundColor: color,
        color: 'white',
      },
      '& .MuiPaginationItem-page.Mui-selected:hover': {
        backgroundColor: color,
      },
    },
  }));

  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles({ theme });

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (page < totalPages) setRankingPage(page + 1);
    },
    onSwipedRight: () => {
      if (page > 1) setRankingPage(page - 1);
    },
    delta: 25,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const options = {
    title: {
      text: `${preTitle} ${t(`dataType_${data.dataType}`).toLowerCase()}`,
      align: 'left',
      style: {
        color: theme.colorText,
        fontFamily: 'roboto',
        fontWeight: 400,
        fontSize: 16,
      },
    },
    chart: {
      type: 'bar',
      height: 250,
      backgroundColor: 'transparent',
      spacingLeft: 0,
    },
    plotOptions: {
      bar: {
        pointWidth: 20,
        borderWidth: 0,
      },
      series: {
        pointStart: 0,
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            textOutline: false,
          },
          formatter: function () {
            if (
              data.dataType === 'casesEvolutionRate' ||
              data.dataType === 'deathsEvolutionRate' ||
              data.dataType === 'vaccinatedDose1PerPopulation' ||
              data.dataType === 'vaccinatedDose2PerPopulation'
            ) {
              return `${t('general_number', {
                value: this.total,
              })}%`;
            }
            return t('general_number', {
              value: this.total,
            });
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        if (
          data.dataType === 'casesEvolutionRate' ||
          data.dataType === 'deathsEvolutionRate' ||
          data.dataType === 'vaccinatedDose1PerPopulation' ||
          data.dataType === 'vaccinatedDose2PerPopulation'
        ) {
          return `<b>${this.point.category}</b> </br>
          <tr><td style="color: ${this.series.color}">${this.series.name}: </td>
            <td style="text-align: right"><b>${t('general_number', {
              value: this.point.y,
            })}% 
          </b></td></tr>`;
        }
        return `<b>${this.point.category}</b> </br>
        <tr><td style="color: ${this.series.color}">${this.series.name}: </td>
          <td style="text-align: right"><b>${t('general_number', {
            value: this.point.y,
          })} 
        </b></td></tr>`;
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        style: {
          color: theme.colorText,
        },
      },
      gridLineColor: theme.colorSeparator,
    },
    xAxis: {
      categories: data.categories,
      labels: {
        style: {
          color: theme.colorText,
          fontSize: '13px',
        },
        align: 'left',
        reserveSpace: true,
        formatter: (obj) => `${data.position[obj.pos]}º ${obj.value}`,
      },
    },
    series: [
      {
        name: t(`dataType_${data.dataType}`),
        data: data.values,
        color,
      },
    ],
    legend: {
      itemStyle: {
        color: theme.colorText,
      },
    },
    navigation: {
      menuStyle: {
        background: theme.colorBackground,
        border: 'none',
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      },
      menuItemStyle: { color: theme.colorText },
      menuItemHoverStyle: { background: color },
    },
    lang: {
      contextButtonTitle: 'Exportar gráfico',
    },
    exporting: {
      chartOptions: {
        chart: {
          style: {
            backgroundColor: theme.colorBackground,
          },
        },
      },
      menuItemDefinitions: {
        downloadPNG: { text: `${t('charts_printButton')} .png` },
        downloadJPEG: { text: `${t('charts_printButton')} .jpeg` },
        downloadPDF: { text: `${t('charts_printButton')} .pdf` },
        downloadSVG: { text: `${t('charts_printButton')} .svg` },
      },
      buttons: {
        contextButton: {
          symbolFill: theme.colorText,
          symbolStroke: theme.colorText,
          theme: {
            fill: 'transparent',
            states: {
              hover: {
                fill: theme.colorSeparator,
              },
              select: {
                fill: theme.colorSeparator,
              },
            },
          },
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
    },
  };

  return (
    <div {...handlers}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <Pagination
        className={classes.pagination}
        size="small"
        count={totalPages}
        page={page}
        onChange={(event, value) => setRankingPage(value)}
      />
    </div>
  );
}
