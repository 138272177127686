import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useStyles from './styles';

/**
 * This component will render a formated home portal item.
 * @param {Icon} icon image url containing the component icon.
 * @param {The title} title
 * @param {The title} subtitle
 * @param {Classname} classname
 */
export default function HomePortalItem({
  icon,
  title,
  subtitle,
  className,
  link,
  text,
}) {
  HomePortalItem.defaultProps = {
    className: '',
  };

  HomePortalItem.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
  };

  const classes = useStyles();

  return (
    <div className={classNames(className, classes.container)}>
      <img className={classes.icon} src={icon} alt={title} />
      <Typography
        variant="h5"
        className={classes.title}
        style={{ fontWeight: 300 }}
        align="center"
      >
        {title}
      </Typography>
      <Typography
        className={classes.subtitle}
        style={{ fontWeight: 300 }}
        align="center"
      >
        {subtitle}
      </Typography>
      <Link to={link} className={classes.buttonLink}>
        <Button variant="outlined" className={classes.readMore}>
          <Typography className={classes.readMoreText}>{text}</Typography>
        </Button>
      </Link>
    </div>
  );
}
