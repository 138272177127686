import { createUseStyles } from 'react-jss';
import { lightTheme } from '../../../constants/themes';

const useStyles = createUseStyles((theme) => ({
  equation1: {
    width: '100%',
    maxWidth: 700,
    marginBottom: '20px',
    filter: theme === lightTheme ? 'invert(0)' : 'invert(0.8)',
  },
  equation2: {
    width: '100%',
    maxWidth: 220,
    marginBottom: '20px',
    filter: theme === lightTheme ? 'invert(0.3)' : 'invert(1)',
  },
}));

export default useStyles;
