import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  imageWrapper: {
    position: 'relative',
    marginLeft: 5,
    marginRight: 5,
  },
  image: {
    borderRadius: 10,
    width: 65,
    height: 65,
    objectFit: 'cover',
    marginTop: 5,
  },
}));

export default useStyles;
