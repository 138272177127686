/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import ProjectionsChart from '../../../components/charts/projections-chart';
import RankingChart from '../../../components/charts/ranking-chart';
import GeneralStatistics from '../../../components/general-statistics';
import FiltersContext from '../../../context/filtering';
import LoaderContext from '../../../context/loading';
import api from '../../../services/api';
import useStyles from '../styles';

/**
 * Provides the data and components of the statistics filters.
 */
export default function ProjectionsStatistics() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  const [generalStatisticsRaw, setGeneralStatisticsRaw] = useState();
  const [generalStatistics, setGeneralStatistics] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [rankingData, setRankingData] = useState();
  const [rankingTotalPages, setRankingTotalPages] = useState();
  const [rankingPage, setRankingPage] = useState(1);
  const { setProjectionsLoading } = useContext(LoaderContext);

  const [chartData, setChartData] = useState();

  const { options, values, projectionsLoads } = useContext(FiltersContext);

  /**
   * This function prepare general statistics data to show the user through general statistics component.
   * @param {statistics data to prepare} data
   */
  function prepareStatisticsData(data) {
    const dataAux = { ...data };

    Object.keys(dataAux).forEach((statistic) => {
      if (dataAux[statistic]) {
        if (
          statistic === 'casesEvolutionRate' ||
          statistic === 'deathsEvolutionRate'
        ) {
          dataAux[statistic] = `${t('general_number', {
            value: Number(dataAux[statistic]),
          })}%`;
        } else {
          dataAux[statistic] = t('general_number', {
            value: Number(dataAux[statistic]),
          });
        }
      } else {
        dataAux[statistic] = '-';
      }
    });

    return dataAux;
  }

  /**
   * This useEffect generate the general statistics component according to the translation and data, and gets last update.
   */
  useEffect(() => {
    if (generalStatisticsRaw) {
      setGeneralStatistics(prepareStatisticsData(generalStatisticsRaw));
      api
        .get(`/api/projections/lastUpdate`, {
          params: {
            territoryType: values.territory.type,
          },
        })
        .then(({ data }) => {
          setLastUpdate(data.date);
        });
    }
  }, [t, generalStatisticsRaw]);

  /**
   * This userEffect gets new general statistics data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (projectionsLoads.options) {
      setProjectionsLoading((rest) => ({ ...rest, generalStatistics: true }));

      api
        .get(`/api/projections/statistics`, {
          params: {
            dataType: values.projectionsDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scenario: values.scenario,
            scale: values.scale,
            day: options.projectionsDates.dates[values.projectionsDate],
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setGeneralStatisticsRaw(data);
            setProjectionsLoading((rest) => ({
              ...rest,
              generalStatistics: false,
            }));
          }
        })
        .catch((error) => {});
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.projectionsDataType,
    values.territory,
    values.scenario,
    values.scale,
    values.projectionsDate,
    options.projectionsDates,
    projectionsLoads.options,
  ]);

  /**
   * This userEffect updates graph data.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (projectionsLoads.options) {
      setProjectionsLoading((rest) => ({ ...rest, chart: true }));

      api
        .get(`/api/projections/chart`, {
          params: {
            dataType: values.projectionsDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scenario: values.scenario,
            day: options.projectionsDates.dates[values.projectionsDate],
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setChartData(data);
            setProjectionsLoading((rest) => ({ ...rest, chart: false }));
          }
        })
        .catch((error) => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    values.projectionsDataType,
    values.territory,
    values.scenario,
    values.projectionsDate,
    options.projectionsDates,
    projectionsLoads.options,
  ]);

  /**
   * This userEffect gets new ranking data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (projectionsLoads.options) {
      setProjectionsLoading((rest) => ({ ...rest, ranking: true }));

      api
        .get(`/api/projections/ranking`, {
          params: {
            dataType: values.projectionsDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scenario: values.scenario,
            scale: values.scale,
            day: options.projectionsDates.dates[values.projectionsDate],
            page: 1,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingTotalPages(data.pages);
            setRankingData(data);
            setRankingPage(1);
            if (data.cityPage > 0) setRankingPage(data.cityPage);
            setProjectionsLoading((rest) => ({ ...rest, ranking: false }));
          }
        })
        .catch((error) => {});
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.projectionsDataType,
    values.territory,
    values.scenario,
    values.scale,
    values.projectionsDate,
    options.projectionsDates,
    projectionsLoads.options,
  ]);

  /**
   * This userEffect gets new ranking data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (projectionsLoads.options) {
      api
        .get(`/api/projections/ranking`, {
          params: {
            dataType: values.projectionsDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scenario: values.scenario,
            scale: values.scale,
            day: options.projectionsDates.dates[values.projectionsDate],
            page: rankingPage,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingData(data);
          }
        })
        .catch((error) => {});
    }

    return () => {
      isSubscribed = false;
    };
  }, [rankingPage]);

  return useMemo(
    () => (
      <>
        {generalStatistics && (
          <div className={classes.statisticItem}>
            <GeneralStatistics
              data={generalStatistics}
              sources={[
                {
                  href: 'http://www.brasil.io',
                  name: 'brasilio',
                  rel: 'noopener noreferrer',
                },
                {
                  href: '/method-projections',
                  name: 'models',
                  rel: '',
                },
                {
                  href: '/method-projections',
                  name: 'projectionsDisclaimer',
                  rel: '',
                },
              ]}
              lastUpdate={lastUpdate}
              firstColor={theme.colorPrimary}
              secondColor={theme.colorSecondary}
            />
          </div>
        )}

        {chartData && (
          <div className={classes.statisticItem}>
            <ProjectionsChart data={chartData} />
          </div>
        )}

        {rankingData && (
          <div className={classes.statisticItem}>
            <RankingChart
              preTitle={t('projectionRankingChart_preTitle')}
              data={rankingData}
              totalPages={rankingTotalPages}
              page={rankingPage}
              setRankingPage={setRankingPage}
              color={theme.colorPrimary}
            />
          </div>
        )}
      </>
    ),
    [t, classes, generalStatistics, chartData, rankingData, rankingTotalPages]
  );
}
