import React, { useState, useEffect } from 'react';
import { useTheme } from 'react-jss';
import { Button, Grow, Menu, MenuItem, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@material-ui/icons';
import useStyles from './styles';
import HeaderButton from '../header-button';
import { availableMaps } from '../../../constants/general';

/**
 * The MapsButton component creates a responsible list of availables maps buttons.
 */
function MapsButton() {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [mapsMenuAnchor, setMapsMenuAnchor] = useState(null);
  const [mapsButtonTranslation, setMapsButtonTranslation] = useState();
  const [collapsibleButtonsList, setCollapsibleButtonsList] = useState([]);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [mapSelected, setMapSelected] = useState([]);
  const [selectedColor, setSelectedColor] = useState();

  /**
   * This function changes the header button color according by page.
   */
  useEffect(() => {
    if (location.pathname === '/vaccination') {
      setSelectedColor(theme.colorTertiary);
    } else {
      setSelectedColor(theme.colorPrimary);
    }
  }, [location]);

  const classes = useStyles({ selectedColor, theme });

  /**
   * This function opens the menu.
   * @param {Click} event
   */
  function handleMapsMenuClick(event) {
    setMapsMenuAnchor(event.currentTarget);
  }

  /**
   * This function closes the menu.
   */
  function handleMapsMenuClose() {
    setMapsMenuAnchor(null);
  }

  /**
   * This useEffect selects which text will appear on collapsed button.
   */
  useEffect(() => {
    const availableMapsArray = Object.keys(availableMaps);
    const found = availableMapsArray.find(
      (map) => availableMaps[map].url === location.pathname
    );

    setMapsButtonTranslation(
      found ? availableMaps[found].translation : 'header_mapsPageButton'
    );
  }, [location]);

  /**
   * This useEffect fills the collapsible buttons list.
   */
  useEffect(() => {
    const availableMapsArray = Object.keys(availableMaps);

    const collapsibleButtonsListAux = availableMapsArray.map((map, index) => (
      <div key={availableMaps[map].url} className={classes.collapsibleButtons}>
        <HeaderButton
          url={availableMaps[map].url}
          translation={availableMaps[map].translation}
          primaryColor={selectedColor}
        />
        {index < availableMapsArray.length - 1 && (
          <div className={classes.verticalSeparator} />
        )}
      </div>
    ));

    setCollapsibleButtonsList(collapsibleButtonsListAux);
  }, [theme, selectedColor]);

  /**
   * This useEffect fills the menu items list.
   */
  useEffect(() => {
    const availableMapsArray = Object.keys(availableMaps);

    const collapsibleButtonsListAux = availableMapsArray.map((map) => (
      <MenuItem
        key={availableMaps[map].url}
        onClick={() => {
          history.push(availableMaps[map].url);
          handleMapsMenuClose();
        }}
      >
        {t(availableMaps[map].translation)}
      </MenuItem>
    ));

    setMenuItemsList(collapsibleButtonsListAux);
  }, [t]);

  /**
   * This useEffect toggle the selection alert.
   */
  useEffect(() => {
    const availableMapsArray = Object.keys(availableMaps);
    const found = availableMapsArray.find(
      (map) => availableMaps[map].url === location.pathname
    );

    setMapSelected(found);
  }, [location]);

  return (
    <>
      {collapsibleButtonsList}
      <div className={classes.mapsButton}>
        <Button
          aria-owns={mapsMenuAnchor ? 'maps-menu' : undefined}
          aria-haspopup="true"
          onClick={handleMapsMenuClick}
          endIcon={<ExpandMore className={classes.iconButton} />}
        >
          <Typography
            className={classes.mapsButtonText}
            style={{ fontWeight: 300 }}
          >
            {t(mapsButtonTranslation)}
          </Typography>
          <Grow in={Boolean(mapSelected)} timeout={200}>
            <div className={classes.selectionAlert} />
          </Grow>
        </Button>
        <Menu
          id="maps-menu"
          anchorEl={mapsMenuAnchor}
          open={Boolean(mapsMenuAnchor)}
          onClose={handleMapsMenuClose}
          PopoverClasses={{ root: classes.menuPopOver }}
        >
          {menuItemsList}
        </Menu>
      </div>
    </>
  );
}

export default MapsButton;
