import React, { useContext } from 'react';
import { useTheme } from 'react-jss';
import { SwishSpinner } from 'react-spinners-kit';
import LoaderContext from '../../../context/loading';
import useStyles from '../styles';

/**
 * This component will render a map loader.
 */
export default function ProjectionsLoader() {
  const { isProjectionsLoading } = useContext(LoaderContext);
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div
      className={classes.centeredLoader}
      style={{ opacity: isProjectionsLoading ? '1' : '0' }}
    >
      <SwishSpinner
        size={40}
        frontColor={theme.colorPrimary}
        backColor={theme.colorSecondary}
      />
    </div>
  );
}
