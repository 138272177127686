import { useEffect, useState } from 'react';

/**
 * A function hook to select the last date.
 * @param {A date array} dateArray
 */
export default function useDate(dateArray) {
  const [date, setDate] = useState(dateArray ? dateArray.length - 1 : 0);

  /**
   * Set new date value if it's updated
   */
  useEffect(() => {
    if (dateArray) {
      setDate(dateArray.length - 1);
    }
  }, [dateArray]);

  return [date, setDate];
}
