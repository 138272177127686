import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Grow, Typography } from '@material-ui/core';
import useStyles from './styles';

/**
 * The HeaderButton component creates a button with the header pattern.
 */
function HeaderButton({
  className,
  translation,
  autoSelect,
  selected,
  onClick,
  url,
  primaryColor,
}) {
  HeaderButton.defaultProps = {
    onClick: () => {},
    className: '',
    autoSelect: true,
    selected: false,
    primaryColor: undefined,
  };

  HeaderButton.propTypes = {
    translation: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    autoSelect: PropTypes.bool,
    primaryColor: PropTypes.string,
  };

  const theme = useTheme();
  const classes = useStyles({ primaryColor, theme });
  const { t } = useTranslation();
  const [autoSelected, setAutoSelected] = useState(selected);
  const location = useLocation();

  /**
   * This useEffect hook chooses if the button is selected using the current path name.
   */
  useEffect(() => {
    setAutoSelected(location.pathname === url);
  }, [location]);

  return (
    <Link to={url} className={classNames(classes.container, className)}>
      <Button onClick={onClick}>
        <Typography className={classes.text} style={{ fontWeight: 300 }}>
          {t(translation)}
        </Typography>
        <Grow in={autoSelect ? autoSelected : selected} timeout={200}>
          <div className={classes.selectionAlert} />
        </Grow>
      </Button>
    </Link>
  );
}

export default HeaderButton;
