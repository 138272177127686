/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';

/**
 * This component is a stylized chart to display pyramid data.
 * @param {Object with three arrays: ages, men, women} data
 */
export default function PyramidChart({ data }) {
  PyramidChart.defaultProps = {
    data: undefined,
  };

  PyramidChart.propTypes = {
    data: PropTypes.shape(),
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const { t } = useTranslation();

  const options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
      padding: 0,
      height: 350,
      spacing: [10, 10, 15, 0],
      zoomType: 'x',
    },
    title: {
      text: `${t(`dataType_${data.dataType}`)} ${t(`pyramidChart_posTitle`)}`,
      align: 'left',
      style: {
        color: theme.colorText,
        fontFamily: 'roboto',
        fontWeight: 400,
        fontSize: 16,
      },
    },
    xAxis: [
      {
        categories: data.ages,
        reversed: false,
        labels: {
          step: 1,
          style: {
            color: theme.colorText,
          },
        },
      },
      {
        // mirror axis on right side
        opposite: true,
        reversed: false,
        categories: data.ages,
        linkedTo: 0,
        labels: {
          step: 1,
          style: {
            color: theme.colorText,
          },
        },
      },
    ],
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: theme.colorText,
        },
        formatter() {
          return `${Math.abs(this.value)}%`;
        },
      },
      margin: 0,
      gridLineColor: theme.colorSeparator,
    },

    plotOptions: {
      series: {
        stacking: 'normal',
      },
      bar: {
        borderWidth: 0,
      },
    },

    tooltip: {
      formatter() {
        return (
          `<b>${this.series.name}, ${t(`pyramidChart_age`)} ${
            this.point.category
          }</b><br/>` +
          ` ${t(`dataType_${data.dataType}`)}: <b>${Highcharts.numberFormat(
            Math.abs(this.point.y),
            2
          )}%</b>`
        );
      },
    },
    legend: {
      itemStyle: {
        color: theme.colorText,
      },
    },
    colors: [theme.colorTertiary, theme.colorQuaternary],
    series: [
      {
        name: t(`pyramidChart_men`),
        data: data.men,
      },
      {
        name: t(`pyramidChart_women`),
        data: data.women,
      },
    ],
    navigation: {
      menuStyle: {
        background: theme.colorBackground,
        border: 'none',
        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
      },
      menuItemStyle: { color: theme.colorText },
      menuItemHoverStyle: { background: theme.colorTertiary },
    },
    lang: {
      contextButtonTitle: 'Exportar gráfico',
    },
    exporting: {
      chartOptions: {
        chart: {
          style: {
            backgroundColor: theme.colorBackground,
          },
        },
      },
      menuItemDefinitions: {
        downloadPNG: { text: `${t('charts_printButton')} .png` },
        downloadJPEG: { text: `${t('charts_printButton')} .jpeg` },
        downloadPDF: { text: `${t('charts_printButton')} .pdf` },
        downloadSVG: { text: `${t('charts_printButton')} .svg` },
      },
      buttons: {
        contextButton: {
          symbolFill: theme.colorText,
          symbolStroke: theme.colorText,
          theme: {
            fill: 'transparent',
            states: {
              hover: {
                fill: theme.colorSeparator,
              },
              select: {
                fill: theme.colorSeparator,
              },
            },
          },
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
          ],
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
