import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-map-gl';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FiltersContext from '../../../context/filtering';
import useStyles from '../styles';
import { vaccinationDataTypes } from '../../../constants/general';
import { isTouchDevice } from '../../../constants/utils';

/**
 * Provides the map popup.
 */
export default function VaccinationMapPopup({
  feature,
  lng,
  lat,
  accessTerritory,
  closePopup,
}) {
  VaccinationMapPopup.defaultProps = {
    feature: null,
    lng: null,
    lat: null,
    accessTerritory: null,
    closePopup: null,
  };

  VaccinationMapPopup.propTypes = {
    feature: PropTypes.shape(),
    lng: PropTypes.number,
    lat: PropTypes.number,
    accessTerritory: PropTypes.func,
    closePopup: PropTypes.func,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const { values } = useContext(FiltersContext);

  /**
   * The component will be rendered only if there are feature and properties.
   */
  if (!feature || !feature.properties) return null;

  return (
    <Popup
      className={classes.popup}
      latitude={lat}
      longitude={lng}
      closeButton={false}
    >
      <Typography variant="subtitle1" align="center">
        {feature.properties.name}
      </Typography>
      <div className={classes.popupSeparator} />
      <Typography variant="caption">
        {`${t(vaccinationDataTypes[values.vaccinationDataType].translation)}:`}
      </Typography>
      <Typography variant="subtitle2" style={{ color: theme.colorQuaternary }}>
        {t('general_number', { value: feature.properties.value })}
        {(values.vaccinationDataType === 'vaccinatedDose1PerPopulation' ||
          values.vaccinationDataType === 'vaccinatedDose2PerPopulation') &&
          '%'}
      </Typography>
      {isTouchDevice() && values.territory.type !== values.scale && (
        <>
          <div className={classes.popupSeparator} style={{ marginTop: 5 }} />
          <div
            className={classes.popupButton}
            onClick={() => {
              if (accessTerritory) accessTerritory();
              if (closePopup) closePopup();
            }}
            role="button"
            onKeyDown={() => {
              if (accessTerritory) accessTerritory();
              if (closePopup) closePopup();
            }}
            tabIndex={0}
          >
            <Typography
              variant="subtitle2"
              style={{ marginTop: 5 }}
              align="center"
            >
              {t('mapPopup_accessButton')}
            </Typography>
            <ArrowForwardIosIcon
              style={{ fontSize: 12, marginTop: 4, marginLeft: 10 }}
            />
          </div>
        </>
      )}
    </Popup>
  );
}
