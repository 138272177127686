import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography, withStyles } from '@material-ui/core';
import { useTheme } from 'react-jss';
import useStyles from './styles';

/**
 * This component renders a home collaborator item.
 */
export default function HomeCollaborator({ name, image, link, instituition }) {
  HomeCollaborator.defaultProps = {
    link: undefined,
  };

  HomeCollaborator.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string,
    instituition: PropTypes.string.isRequired,
  };

  const classes = useStyles();
  const theme = useTheme();

  const StyledTooltip = withStyles(() => ({
    arrow: {
      '&:before': {
        border: `1px solid ${theme.colorSeparator}`,
        backgroundColor: theme.colorBackground,
      },
      color: theme.colorBackground,
    },
    tooltip: {
      backgroundColor: theme.colorBackground,
      border: `1px solid ${theme.colorSeparator}`,
      color: theme.colorText,
    },
  }))(Tooltip);

  /**
   * This function returns the tooltip content
   * @returns the Tooltip Content
   */
  function ToolTipContent() {
    return (
      <StyledTooltip
        theme={theme}
        disableFocusListener
        title={
          <>
            <Typography style={{ fontWeight: 400, fontSize: 14 }}>
              {name}
            </Typography>
            <Typography style={{ fontWeight: 300, fontSize: 14 }}>
              {instituition}
            </Typography>
          </>
        }
        arrow
      >
        <img className={classes.image} src={image} alt={name} />
      </StyledTooltip>
    );
  }

  if (link) {
    return (
      <a
        rel="noreferrer"
        target="_blank"
        href={link}
        className={classes.imageWrapper}
      >
        {ToolTipContent()}
      </a>
    );
  }

  return <span className={classes.imageWrapper}>{ToolTipContent()}</span>;
}
