import React, { useContext, useEffect } from 'react';
import { useTheme } from 'react-jss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import { Brightness4, Brightness7 } from '@material-ui/icons';
import useStyles from './styles';
import platformLogo from '../../assets/images/logo.svg';
import TranslationsButton from '../header/translations-button';
import ThemeContext from '../../context/theming';
import { lightTheme } from '../../constants/themes';
import PortalButton from '../portal-button';

/**
 * Generete a formated document page.
 */
function Document({ children }) {
  Document.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
      .isRequired,
  };

  const theme = useTheme();
  const { t } = useTranslation();
  const { selectedTheme, switchTheme } = useContext(ThemeContext);
  const history = useHistory();
  const classes = useStyles({ theme });

  /**
   * Sets the sroll position to 0 when the page is loaded.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * This function handle logo click.
   */
  function handleLogoClick() {
    history.push('/');
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.header}>
          <div
            role="button"
            className={classes.logoWrapper}
            onClick={handleLogoClick}
            onKeyDown={handleLogoClick}
            tabIndex={0}
          >
            <div className={classes.platformLogoWrapper}>
              <img
                className={classes.platformLogo}
                src={platformLogo}
                alt={t('general_platformName')}
              />
            </div>

            <div className={classes.platformName}>
              {t('general_platformName')}
              <div className={classes.platformSubtitle}>
                {t('general_platformSubtitle')}
              </div>
            </div>
          </div>
          <div className={classes.rightButtonsWrapper}>
            <PortalButton />
            <div className={classes.verticalSeparator} />
            <TranslationsButton />
            <div className={classes.verticalSeparator} />
            <IconButton aria-label="theme" onClick={switchTheme}>
              {selectedTheme === lightTheme ? (
                <Brightness4 className={classes.iconButton} />
              ) : (
                <Brightness7 className={classes.iconButton} />
              )}
            </IconButton>
          </div>
        </div>
        <div className={classes.childrenWrapper}>{children}</div>
        <div className={classes.footerSeparator} />
        <div className={classes.footerLastElements}>
          <div
            role="button"
            onClick={handleLogoClick}
            onKeyDown={handleLogoClick}
            tabIndex={0}
            className={classes.footerPlatformLogoContainer}
          >
            <img
              className={classes.footerPlatformLogo}
              src={platformLogo}
              alt={t('general_platformName')}
            />
            <div className={classes.footerPlatformName}>
              {t('general_platformName')}
            </div>
          </div>
          <div className={classes.footerLastText}>
            <Typography style={{ fontWeight: 300 }}>
              {t('homePage_footer_poweredBy')}{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://mapbiomas.org/"
                className={classes.href}
              >
                MapBiomas ©
              </a>
            </Typography>
            <Typography style={{ fontWeight: 300 }}>
              {t('homePage_footer_developedBy')}{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="http://geodatin.com/"
                className={classes.href}
              >
                Geodatin ©
              </a>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * A componnt to format title.
 * @param {Text} children
 */
function Title({ children }) {
  Title.propTypes = {
    children: PropTypes.string.isRequired,
  };
  const theme = useTheme();

  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography
        style={{ color: theme.colorText, fontWeight: 300 }}
        variant="h4"
      >
        {children}
      </Typography>
    </div>
  );
}

/**
 * A componnt to format Paragraph.
 * @param {Paragraph text} children
 */
function Paragraph({ children }) {
  Paragraph.propTypes = {
    children: PropTypes.string.isRequired,
  };
  const theme = useTheme();

  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography
        align="justify"
        style={{ color: theme.colorText, fontWeight: 300 }}
        variant="body1"
      >
        {children}
      </Typography>
    </div>
  );
}

/**
 * A componnt to format Section Title.
 * @param {Title text} children
 */
function SectionTitle({ children }) {
  SectionTitle.propTypes = {
    children: PropTypes.string.isRequired,
  };
  const theme = useTheme();

  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography
        style={{ color: theme.colorText, fontWeight: 400 }}
        variant="h6"
      >
        {children}
      </Typography>
    </div>
  );
}

Document.Title = Title;
Document.Paragraph = Paragraph;
Document.SectionTitle = SectionTitle;

export default Document;
