import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import useLoading from '../hooks/loading';

const LoaderContext = createContext({});

/**
 * The LoadingProvider to provide application loading states.
 * */
export function LoadingProvider({ children }) {
  LoadingProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const [casesLoading, setCasesLoading] = useState({
    map: true,
    generalStatistics: true,
    chart: true,
    ranking: true,
  });

  const [projectionsLoading, setProjectionsLoading] = useState({
    map: true,
    generalStatistics: true,
    chart: true,
    ranking: true,
  });

  const [vaccinationLoading, setVaccinationLoading] = useState({
    map: true,
    generalStatistics: true,
    rankingTerritory: true,
    rankingGroup: true,
    chartSeries: true,
    chartGender: true,
    chartVaccineNames: true,
    chartEthnicity: true,
  });

  return (
    <LoaderContext.Provider
      value={{
        setCasesLoading,
        setProjectionsLoading,
        setVaccinationLoading,
        isCasesLoading: useLoading(casesLoading),
        isProjectionsLoading: useLoading(projectionsLoading),
        isVaccinationLoading: useLoading(vaccinationLoading),
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
}

export default LoaderContext;
