/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss';
import { defaultPageMaxWidth, menuHeightDesktop } from '../../constants/sizes';
import { defaultBackgroundTransition } from '../../constants/styles';
import { lightTheme } from '../../constants/themes';

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',

    '&::before': {
      content: "''",
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor:
        theme === lightTheme
          ? theme.colorSecondaryBackground
          : theme.colorBackground,
      transition: defaultBackgroundTransition,
    },
  },
  content: {
    zIndex: 1000,
    width: '100%',
    maxWidth: defaultPageMaxWidth,
  },
  header: {
    height: menuHeightDesktop,
    display: 'flex',
    flexFlow: 'row nowrap',
    flexDirection: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '0px 15px',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
    marginRight: 5,
    userSelect: 'none',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
  },
  platformLogoWrapper: {
    height: 35,
    width: 35,
    marginRight: 5,
    position: 'relative',
  },
  platformLogo: {
    height: 35,
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  platformName: {
    position: 'relative',
    color: theme.colorText,
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 300,
    marginBottom: 5,

    '@media (max-width: 290px)': {
      display: 'none',
    },
  },
  platformSubtitle: {
    color: theme.colorText,
    fontSize: 10,
    fontFamily: 'Roboto',
    position: 'absolute',
    right: 2,
    opacity: 0.6,
    bottom: -7,
    fontStyle: 'italic',
    textAlign: 'right',
    fontWeight: 400,
  },
  rightButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
  },
  iconButton: {
    color: theme.colorText,
  },
  verticalSeparator: {
    backgroundColor: theme.colorSeparator,
    marginLeft: 3,
    marginRight: 3,
    width: 1,
    height: 20,
  },
  footerSeparator: {
    margin: '25px 0px',
    position: 'relative',
    backgroundColor: theme.colorSeparator,
    height: 1,
    width: '100%',
  },
  footerElements: {
    margin: '25px 0px',
    color: theme.colorText,
  },
  footerLastElements: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px',
    marginBottom: 20,

    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  href: {
    textDecoration: 'inherit',
    color: theme.colorText,
    transition: 'color 0.2s ease',

    '&:hover': {
      color: theme.colorSecondary,
    },
  },
  footerLastText: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    textAlign: 'right',
    color: theme.colorText,

    '@media (max-width: 600px)': {
      marginTop: 15,
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 20,
    },
  },
  footerPlatformLogo: {
    height: 35,
    marginRight: 5,
    pointerEvents: 'none',
  },
  footerPlatformLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
  },
  footerPlatformName: {
    position: 'relative',
    color: theme.colorText,
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  childrenWrapper: {
    padding: '0px 15px',
  },
}));

export default useStyles;
