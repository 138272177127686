import React from 'react';
import { useTheme } from 'react-jss';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

/**
 * This component is a styled selector to receive the portal filters.
 * @param {Names the filter label} filterName
 * @param {A list containing the selection options} options
 * @param {Disable the filter} disabled
 * @param {The selected value} value
 */
export default function FilterSelect({
  filterName,
  options,
  disabled,
  value,
  setValue,
  firstColor,
  secondColor,
}) {
  FilterSelect.defaultProps = {
    options: { 0: { translation: '-' } },
    value: '0',
    disabled: false,
  };

  FilterSelect.propTypes = {
    filterName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.shape(),
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    firstColor: PropTypes.string.isRequired,
    secondColor: PropTypes.string.isRequired,
  };

  const theme = useTheme();
  const classes = useStyles({ firstColor, secondColor, theme });
  const { t } = useTranslation();

  /**
   * The component will be rendered only if there are a options list.
   */
  if (!options) {
    return null;
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel
        className={classes.label}
        id="demo-simple-select-outlined-label"
      >
        {filterName}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={({ target }) => setValue(target.value)}
        label={filterName}
        className={classes.select}
        MenuProps={{ PopoverClasses: { root: classes.menuPopOver } }}
        disabled={disabled}
      >
        {Object.keys(options).map((option) => (
          <MenuItem
            style={{ whiteSpace: 'normal' }}
            key={option}
            value={option}
          >
            {t(options[option].translation)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
