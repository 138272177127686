import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'react-jss';
import { Button, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import useStyles from './styles';
/**
 * The PortalButton component create a custom button.
 */
function PortalButton() {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.portalExtendedButton}>
        <Button
          onClick={() => history.push('/')}
          startIcon={<HomeIcon className={classes.iconButton} />}
        >
          <Typography
            className={classes.portalButtonText}
            style={{ fontWeight: 300 }}
          >
            {t('general_backToPortal')}
          </Typography>
        </Button>
      </div>
      <div className={classes.portalMinorButton}>
        <IconButton onClick={() => history.push('/')}>
          <HomeIcon className={classes.iconButton} />
        </IconButton>
      </div>
    </>
  );
}

export default PortalButton;
