/* eslint-disable no-unused-vars */
import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTheme } from 'react-jss';
import FiltersContext from '../../../context/filtering';
import useStyles from '../styles';

/**
 * Provides the data and components of the indigenous statistics.
 */
export default function IndigenousStatistics() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { options, values, setters, loads } = useContext(FiltersContext);

  return (
    <>
      <div className={classes.statisticItem}>
        <Typography style={{ color: theme.colorText }}>
          Stats Indigenous 1
        </Typography>
      </div>
      <div className={classes.statisticItem}>
        <Typography style={{ color: theme.colorText }}>
          Stats Indigenous 1
        </Typography>
      </div>
      <div className={classes.statisticItem}>
        <Typography style={{ color: theme.colorText }}>
          Stats Indigenous 2
        </Typography>
      </div>
    </>
  );
}
