import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  autocompleteInput: {
    '& .MuiInputBase-input': {
      color: theme.colorText,
    },
  },
  itemsBorder: {
    color: theme.colorText,
    opacity: 0.7,
  },
  partText: {
    color: theme.colorText,
  },
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  root: {
    '& .MuiFormLabel-root': {
      color: theme.colorText,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
    },
    '& .MuiIconButton-root': {
      color: theme.colorText,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colorText,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ secondColor }) => secondColor,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: ({ firstColor }) => firstColor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ firstColor }) => firstColor,
    },
    '& .MuiInputBase-input': {
      color: theme.colorText,
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)',
    },
  },
  popper: {
    '& .MuiPaper-root': {
      backgroundColor: theme.colorTertiaryBackground,
    },
    '& .MuiListSubheader-root': {
      color: ({ firstColor }) => firstColor,
      backgroundColor: theme.colorTertiaryBackground,
    },
    '& .MuiListSubheader-sticky': {
      position: 'relative',
    },
  },
}));

export default useStyles;
