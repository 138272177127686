import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  article1: {
    width: '100%',
    maxWidth: 200,
    marginBottom: 20,
  },
  articleWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
