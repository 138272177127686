import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  link: {
    textDecoration: 'underline',
    color: theme.colorText,
    transition: 'color 0.2s ease',

    '&:hover': {
      color: theme.colorSecondary,
    },
  },
}));

export default useStyles;
