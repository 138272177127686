/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header';
import useStyles from './styles';
import GARoute from '../ga-route';

/**
 * The NavRoute component return a Route containing the Header.
 */
function NavRoute({ exact, path, component: Component }) {
  NavRoute.defaultProps = {
    exact: false,
  };

  NavRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.elementType.isRequired,
  };
  const classes = useStyles();

  return (
    <GARoute
      exact={exact}
      path={path}
      render={(props) => (
        <div className={classes.background}>
          <Header reduced={path === '/'} enableBackground={path !== '/'} />
          <Component {...props} />
        </div>
      )}
    />
  );
}

export default NavRoute;
