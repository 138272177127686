import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    border: '1px solid',
    borderRadius: '20px',
    borderColor: theme.colorSeparator,
    padding: '40px',
    maxWidth: '200px',
    transition: 'box-shadow 0.2s ease',

    '&:hover': {
      boxShadow: '2px 7px 24px -10px rgba(0,0,0,0.2)',
    },
  },
  cardWrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
  icon: { height: '50px' },
  title: {
    color: theme.colorText,
    padding: '20px 0px',
  },
  subtitle: {
    color: theme.colorText,
    paddingBottom: '20px',
  },
  readMore: {
    color: theme.colorPrimary,
    transition: 'all 0.2s ease',
    textDecoration: 'none',

    '&:hover': {
      color: theme.colorSecondary,
    },
  },
  readMoreText: {
    textTransform: 'none',
  },
  href: {
    textDecoration: 'inherit',
  },
}));

export default useStyles;
