import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import RankingChart from '../../../components/charts/ranking-chart';
import SeriesChart from '../../../components/charts/series-chart';
import GeneralStatistics from '../../../components/general-statistics';
import FiltersContext from '../../../context/filtering';
import LoaderContext from '../../../context/loading';
import api from '../../../services/api';
import useStyles from '../styles';

/**
 * Provides the data and components of the cases statistics.
 */
export default function CasesStatistics() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const { options, values, loads } = useContext(FiltersContext);
  const [generalStatisticsRaw, setGeneralStatisticsRaw] = useState();
  const [generalStatistics, setGeneralStatistics] = useState();
  const [lastUpdate, setLastUpdate] = useState();
  const [rankingData, setRankingData] = useState();
  const [rankingTotalPages, setRankingTotalPages] = useState();
  const [rankingPage, setRankingPage] = useState(1);
  const [chartData, setChartData] = useState();
  const { setCasesLoading } = useContext(LoaderContext);

  /**
   * This function prepare general statistics data to show the user through general statistics component.
   * @param {statistics data to prepare} data
   */
  function prepareStatisticsData(data) {
    const dataAux = { ...data };

    Object.keys(dataAux).forEach((statistic) => {
      if (dataAux[statistic]) {
        if (
          statistic === 'casesEvolutionRate' ||
          statistic === 'deathsEvolutionRate'
        ) {
          dataAux[statistic] = `${t('general_number', {
            value: Number(dataAux[statistic]),
          })}%`;
        } else {
          dataAux[statistic] = t('general_number', {
            value: Number(dataAux[statistic]),
          });
        }
      } else {
        dataAux[statistic] = '-';
      }
    });

    return dataAux;
  }

  /**
   * This userEffect gets new general statistics data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (loads.options) {
      setCasesLoading((rest) => ({ ...rest, generalStatistics: true }));

      api
        .get(`/api/cases/statistics`, {
          params: {
            dataType: values.casesDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            day: options.dates[values.date],
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setGeneralStatisticsRaw(data);
            setCasesLoading((rest) => ({ ...rest, generalStatistics: false }));
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.casesDataType,
    values.territory,
    values.scale,
    values.date,
    options.dates,
    loads.options,
  ]);

  /**
   * This useEffect generate the general statistics component according to the translation and data, and gets last update.
   */
  useEffect(() => {
    if (generalStatisticsRaw) {
      setGeneralStatistics(prepareStatisticsData(generalStatisticsRaw));
      api
        .get(`/api/cases/lastUpdate`, {
          params: {
            territoryType: values.territory.type,
          },
        })
        .then(({ data }) => {
          setLastUpdate(data.date);
        });
    }
  }, [t, generalStatisticsRaw]);

  /**
   * This userEffect updates de graph data.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (loads.options) {
      setCasesLoading((rest) => ({ ...rest, chart: true }));

      api
        .get(`/api/cases/chart`, {
          params: {
            dataType: values.casesDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            day: options.dates[values.date],
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setChartData(data);
            setCasesLoading((rest) => ({ ...rest, chart: false }));
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    loads.options,
    values.casesDataType,
    values.territory,
    options.dates,
    values.date,
  ]);

  /**
   * This userEffect gets new ranking data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (loads.options) {
      setCasesLoading((rest) => ({ ...rest, ranking: true }));

      api
        .get(`/api/cases/ranking`, {
          params: {
            dataType: values.casesDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            day: options.dates[values.date],
            page: 1,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingTotalPages(data.pages);
            setRankingData(data);
            setRankingPage(1);
            if (data.cityPage > 0) setRankingPage(data.cityPage);
            setCasesLoading((rest) => ({ ...rest, ranking: false }));
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.casesDataType,
    values.territory,
    values.scale,
    values.date,
    options.dates,
    loads.options,
  ]);

  /**
   * This userEffect gets new ranking data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (loads.options) {
      api
        .get(`/api/cases/ranking`, {
          params: {
            dataType: values.casesDataType,
            territoryType: values.territory.type,
            code: values.territory.code,
            scale: values.scale,
            day: options.dates[values.date],
            page: rankingPage,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingData(data);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [rankingPage]);

  return useMemo(
    () => (
      <>
        {generalStatistics && (
          <div className={classes.statisticItem}>
            <GeneralStatistics
              data={generalStatistics}
              lastUpdate={lastUpdate}
              firstColor={theme.colorPrimary}
              sources={[
                {
                  href: 'http://www.brasil.io',
                  name: 'brasilio',
                  rel: 'noopener noreferrer',
                },
                {
                  href: '/method-projections',
                  name: 'models',
                  rel: '',
                },
              ]}
              secondColor={theme.colorSecondary}
            />
          </div>
        )}

        {chartData && (
          <div className={classes.statisticItem}>
            <SeriesChart data={chartData} color={theme.colorPrimary} />
          </div>
        )}

        {rankingData && (
          <div className={classes.statisticItem}>
            <RankingChart
              preTitle={t('rankingChart_territory_preTitle')}
              data={rankingData}
              totalPages={rankingTotalPages}
              page={rankingPage}
              setRankingPage={setRankingPage}
              color={theme.colorPrimary}
            />
          </div>
        )}
      </>
    ),
    [t, classes, chartData, rankingData, rankingTotalPages]
  );
}
