/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom';
import HC_exporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
import 'fontsource-roboto';
import 'dotenv';
import './i18n/config';
import './assets/css/index.css';
import { ThemeProvider } from './context/theming';
import Routes from './routes';

HC_exporting(Highcharts);

ReactDOM.render(
  <ThemeProvider>
    <Routes />
  </ThemeProvider>,
  document.getElementById('root')
);
