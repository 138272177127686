import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    margin: '0px 5px',
    textDecoration: 'none',
  },
  text: {
    color: theme.colorText,
    fontSize: '1rem',
    textTransform: 'none',
  },
  selectionAlert: {
    position: 'absolute',
    width: 'calc(100% + 14px)',
    height: 5,
    bottom: -14,
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: ({ primaryColor }) => primaryColor,
    pointerEvents: 'none',

    '@media (max-width: 599px)': {
      bottom: -10,
    },
  },
}));

export default useStyles;
