import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import GARoute from './components/ga-route';
import NavRoute from './components/nav-route';
import Home from './pages/home';
import Map from './pages/map';
import MethodData from './pages/method/data';
import MethodProjections from './pages/method/projections';
import MethodPublications from './pages/method/publications';
/* import Reports from './pages/reports'; */

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <NavRoute exact path="/" component={Home} />
        <NavRoute exact path="/cases" component={Map} />
        <NavRoute exact path="/projections" component={Map} />
        <NavRoute exact path="/vaccination" component={Map} />
        <GARoute exact path="/method-data" component={MethodData} />
        <GARoute
          exact
          path="/method-projections"
          component={MethodProjections}
        />
        <GARoute
          exact
          path="/method-publications"
          component={MethodPublications}
        />
        {/* <NavRoute path="/indigenous" component={Map} /> */}
        {/* <NavRoute path="/reports" component={Reports} /> */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
