import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';

/**
 * This component will render a formated home portal item.
 * @param {The title} title
 * @param {An array containing a link shape, containing a text, href, a conditional and a target} links
 */
export default function HomeFooterLinks({ title, links }) {
  HomeFooterLinks.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  const classes = useStyles();

  return (
    <div id="homeFooterLink" className={classes.container}>
      <Typography className={classes.title} style={{ fontWeight: 400 }}>
        {title}
      </Typography>
      {links.map((link) =>
        link.link ? (
          <Link key={link.link} to={link.link} className={classes.href}>
            <Typography
              className={classes.subtitle}
              style={{ fontWeight: 300 }}
            >
              {link.text}
            </Typography>
          </Link>
        ) : (
          <a
            key={link.href}
            href={link.href}
            target={link.target}
            className={classes.href}
          >
            <Typography
              className={classes.subtitle}
              style={{ fontWeight: 300 }}
            >
              {link.text}
            </Typography>
          </a>
        )
      )}
    </div>
  );
}
