import { createUseStyles } from 'react-jss';
import {
  defaultPageMaxWidth,
  menuHeightDesktop,
  menuHeightMobile,
} from '../../constants/sizes';

const useStyles = createUseStyles((theme) => ({
  container: {
    width: '100%',
  },
  sectionWrapper: {
    width: '100%',
    padding: '0px 15px',
    maxWidth: defaultPageMaxWidth,
    position: 'relative',
  },
  title: {
    color: theme.colorText,
    paddingBottom: 20,
  },
  subtitle: {
    color: theme.colorText,
    paddingBottom: 20,
  },
  subtitle2: {
    color: theme.colorText,
    paddingBottom: 20,
    maxWidth: 520,
  },
  helloSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    paddingTop: menuHeightDesktop,
    paddingBottom: 250,
    marginBottom: -250,

    '@media (max-width: 599px)': {
      paddingTop: menuHeightMobile,
    },
  },
  helloWrapper: {
    display: 'flex',
    flexDirection: 'horizontal',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    maxHeight: 1000,

    '@media (max-width: 900px)': {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column-reverse',
    },
  },
  helloLeftColumn: {
    width: '100%',

    '@media (max-width: 900px)': {
      maxWidth: '500px',
      textAlign: 'center',
    },
  },
  helloRightColumn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',

    '@media (max-width: 900px)': {
      marginBottom: '30px',
      justifyContent: 'center',
    },
  },
  helloVirusImg: {
    height: 300,

    '@media (max-width: 900px)': {
      height: 150,
    },
  },
  helloBackground: {
    position: 'absolute',
    pointerEvents: 'none',
    top: -100,
    left: '50%',
    height: '100%',
    transform: 'scaleX(1) translateX(-20%)',

    '@media (max-width: 1100px)': {
      transform: 'scaleX(0.7) translateX(-50%)',
    },
    '@media (max-width: 800px)': {
      transform: 'scaleX(0.6) translateX(-60%)',
    },
    '@media (max-width: 600px)': {
      transform: 'scaleX(0.6) translateX(-60%)',
    },
    '@media (max-width: 400px)': {
      transform: 'scaleX(0.6) translateX(-45%)',
    },
  },
  helloButton: {
    '&.MuiButton-outlined': {
      borderColor: theme.colorPrimary,
      borderRadius: 50,
    },
    '&.MuiButton-root': {
      color: theme.colorPrimary,
    },
  },
  helloButtonText: {
    textTransform: 'none',
    color: theme.colorPrimary,
  },
  sectionContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    marginTop: 50,
    marginBottom: 200,
    scrollMarginTop: '10vh',

    '@media (max-width: 900px)': {
      scrollMarginTop: '30px',
    },
  },
  portalPresentation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  methodologyPresentation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  partnersPresentation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  partners: {
    marginTop: '50px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  collaboratorsPresentation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linePartners: {
    paddingBottom: '20px',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  partner: {
    height: '100%',
    padding: '20px 20px',
    opacity: 0.7,
    filter: 'grayscale(100%)',
  },
  portalItemsContainer: {
    display: 'flex',
    flexDirection: 'horizontal',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    marginTop: 50,

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  methodologyCardsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginTop: 50,
    justifyContent: 'space-around',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  portalItem: {
    '@media (max-width: 900px)': {
      marginBottom: 50,
    },
  },
  methodologyCard: {
    '@media (max-width: 900px)': {
      marginBottom: 50,
    },
  },
  institutionalBackground: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    height: '140%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',

    '@media (max-width: 900px)': {
      width: '100%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  institutionalContentWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  institutionalVideoWrapper: {
    position: 'relative',
    width: '100%',
  },
  institutionalVideoResolution: {
    position: 'relative',
    width: '100%',
    paddingTop: `${(9 * 100) / 16}%`,
  },
  institutionalVideo: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    transform: 'scale(0.86) translateY(-12px)',
  },
  institutionalNotebook: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
  institutionalTextWrapper: {
    width: '60%',
    marginLeft: 25,

    '@media (max-width: 900px)': {
      marginLeft: 0,
      width: '100%',
      marginTop: 70,
    },
    '@media (max-width: 700px)': {
      marginTop: 40,
    },
  },
  institutionalText: {
    color: theme.colorText,
    paddingBottom: 20,
  },
  footerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    backgroundColor: theme.colorFooterBackground,
  },
  footerSeparator: {
    margin: '25px 0px',
    position: 'relative',
    backgroundColor: theme.colorSeparator,
    height: 1,
    width: '100%',
  },
  footerElements: {
    margin: '25px 0px',
    color: theme.colorText,
  },
  href: {
    textDecoration: 'inherit',
    color: theme.colorText,
    transition: 'color 0.2s ease',

    '&:hover': {
      color: theme.colorSecondary,
    },
  },
  imageLink: {
    '&:focus': {
      outline: `none`,
    },
  },
  footerContactHref: {
    marginLeft: 10,
    textDecoration: 'inherit',
    color: theme.colorPrimary,
    transition: 'color 0.2s ease',

    '&:hover': {
      color: theme.colorSecondary,
    },
  },
  footerLastElements: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  platformLogo: {
    height: 35,
    marginRight: 5,
    pointerEvents: 'none',
  },
  platformName: {
    position: 'relative',
    color: theme.colorText,
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
  platformLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  collaboratorsContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },
  collaboratorsWrapper: {
    position: 'absolute',
    width: 'calc(100% + 30px)',
    height: '170%',
    top: 0,
    left: -15,
    overflow: 'hidden',
    transform: 'translateY(-20%)',
  },
  collaboratorsBackground: {
    position: 'absolute',
    left: '50%',
    top: 0,
    height: '100%',
    zIndex: -2,
    pointerEvents: 'none',
    transform: 'translateX(-50%)',
  },
  collaboratorsImages: {
    position: 'relative',
    display: 'inline',
    textAlign: 'center',
    padding: '0px 150px',

    '@media (max-width: 900px)': {
      padding: '0px 100px',
    },
    '@media (max-width: 800px)': {
      padding: '0px 50px',
    },
    '@media (max-width: 700px)': {
      padding: '0px 0px',
    },
  },
  footerLastText: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    textAlign: 'right',

    '@media (max-width: 600px)': {
      marginTop: 15,
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  footerContact: {
    color: theme.colorPrimary,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  footerLinks: {
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width: 600px)': {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& #homeFooterLink': {
        marginTop: 50,
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  },
}));

export default useStyles;
