import { createUseStyles } from 'react-jss';
import { menuHeightDesktop, menuHeightMobile } from '../../constants/sizes';
import { defaultBackgroundTransition } from '../../constants/styles';

const useStyles = createUseStyles((theme) => ({
  backgroundWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: menuHeightDesktop,
    width: '100%',
    backgroundColor: theme.colorBackground,
    transition: defaultBackgroundTransition,

    '@media (max-width: 599px)': {
      height: menuHeightMobile,
    },
  },
  container: {
    backgroundColor: theme.colorSecondaryBackground,
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.15)',
    position: 'absolute',
    zIndex: 1000,
    height: menuHeightDesktop,
    top: 0,
    left: 0,
    right: 0,
    transition: defaultBackgroundTransition,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 15px',

    '@media (max-width: 599px)': {
      height: menuHeightMobile,
    },
  },
  containerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
    justifyContent: 'space-between',
    transition: 'max-width 0.3s ease',
  },
  logoWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
    marginRight: 5,
    userSelect: 'none',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
  },
  desktopWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'horizontal',

    '@media (max-width: 620px)': {
      display: 'none',
    },
  },
  platformLogoWrapper: {
    height: 35,
    width: 35,
    marginRight: 5,
    position: 'relative',
  },
  platformLogo: {
    height: 35,
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  platformName: {
    position: 'relative',
    color: theme.colorText,
    fontSize: 22,
    fontFamily: 'Roboto',
    fontWeight: 300,
    marginBottom: 5,

    '@media (max-width: 250px)': {
      display: 'none',
    },
  },
  platformSubtitle: {
    color: theme.colorText,
    fontSize: 10,
    fontFamily: 'Roboto',
    position: 'absolute',
    right: 2,
    opacity: 0.6,
    bottom: -7,
    fontStyle: 'italic',
    textAlign: 'right',
    fontWeight: 400,
  },
  leftButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
  },
  rightButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',
  },
  iconButton: {
    color: theme.colorText,
  },
  verticalSeparator: {
    backgroundColor: theme.colorSeparator,
    marginLeft: 3,
    marginRight: 3,
    width: 1,
    height: 20,
  },
  mobileWrapper: {
    display: 'none',

    '@media (max-width: 620px)': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'horizontal',
    },
  },
  mobileMapsButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'horizontal',

    '@media (max-width: 420px)': {
      display: 'none',
    },
  },
}));

export default useStyles;
