import { useEffect, useState } from 'react';

/**
 * This hook verify if the params are loading.
 * @param {Component loading states} loadings
 */
export default function useLoading(loadings) {
  const [isLoading, setLoading] = useState(false);

  /**
   * This useEffect sets the loading hook return.
   */
  useEffect(() => {
    let isLoadingAux = false;

    Object.keys(loadings).forEach((key) => {
      if (loadings[key]) isLoadingAux = true;
    });

    setLoading(isLoadingAux);
  }, [loadings]);

  return isLoading;
}
