import { createUseStyles } from 'react-jss';
import { defaultBackgroundTransition } from '../../constants/styles';

const useStyles = createUseStyles((theme) => ({
  background: {
    '&::after': {
      content: "''",
      backgroundColor: theme.colorBackground,
      transition: defaultBackgroundTransition,
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    },
  },
}));

export default useStyles;
