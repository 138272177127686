import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      color: theme.colorText,
    },
    '& .MuiFormLabel-root': {
      color: theme.colorText,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: ({ firstColor }) => firstColor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ firstColor }) => firstColor,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colorText,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ secondColor }) => secondColor,
    },
    '& .MuiSelect-icon': {
      color: theme.colorText,
    },
  },
  label: {},
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  menuPopOver: {
    '& .MuiPaper-root': {
      backgroundColor: theme.colorTertiaryBackground,
      color: theme.colorText,
    },
  },
}));

export default useStyles;
