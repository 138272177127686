import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { defaultOptions } from '../constants/general';

const MapContext = createContext({});

/**
 * The MapContext is context to provide options to map.
 * */
export function MapProvider({ children }) {
  MapProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const [viewport, setViewport] = useState(defaultOptions.mapViewport);
  const [popup, setPopup] = useState(null);
  const [onHover, setOnHover] = useState(null);
  const [onClick, setOnClick] = useState(null);

  return (
    <MapContext.Provider
      value={{
        viewport,
        setViewport,
        popup,
        setPopup,
        onHover,
        setOnHover,
        onClick,
        setOnClick,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

export default MapContext;
