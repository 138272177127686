/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

/**
 * The GARoute component return a Route containing the Google Analytics.
 */
function GARoute(props) {
  const location = useLocation();

  /**
   * This useEffect depends of the location to send a pageview to Google Analytics.
   */
  useEffect(() => {
    window.gtag('send', 'page_view', {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location]);

  return <Route {...props} />;
}

export default GARoute;
