/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { ListSubheader, TextField, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import FiltersContext from '../../context/filtering';
import { defaultOptions } from '../../constants/general';
import api from '../../services/api';

/**
 * This component creates a Autocomplete containing a list of available territories.
 */
function TerritoriesAutocomplete({ firstColor, secondColor }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({ firstColor, secondColor, theme });
  const { options, values, setters } = useContext(FiltersContext);
  const [textValue, setTextValue] = useState(defaultOptions.territory.name);
  const [loading, setLoading] = useState(false);

  /**
   * This useEffect controls the async autocomplete.
   */
  useEffect(() => {
    let active = true;

    if (setters.setTerritoriesOptions && values.territory) {
      if (textValue.length <= 1) {
        setters.setTerritoriesOptions(
          values.territory ? [values.territory] : []
        );
      } else if (textValue.length > 1 && !/^\s*$/.test(textValue)) {
        setLoading(true);

        api.get(`/api/territories/${textValue}`).then(({ data }) => {
          if (active) {
            const index = data.findIndex(
              (element) => element.type === values.territory.type
            );

            /*
            If there are an element of the current type on list, the selected element 
            should be inserted on correct sorted position on list to avoid duplied group headers.
            If the same element is already on list, there is no need to include it again inside list array.
            */
            if (index >= 0) {
              if (
                !data.find((element) => element.code === values.territory.code)
              ) {
                data.splice(index, 0, values.territory);
              }
              setters.setTerritoriesOptions(data);
            } else {
              setters.setTerritoriesOptions([values.territory, ...data]);
            }

            setLoading(false);
          }
        });
      }
    }

    return () => {
      active = false;
    };
  }, [textValue, values.territory]);

  return useMemo(
    () => (
      <Autocomplete
        autoComplete
        autoHighlight
        selectOnFocus
        disableClearable
        options={options.territories}
        getOptionSelected={(option, value) => option.code === value.code}
        getOptionLabel={(option) => option.name}
        classes={{
          listbox: classes.listbox,
          root: classes.root,
          popper: classes.popper,
        }}
        loading={loading}
        loadingText={t('map_territoriesAutocompleteLoading')}
        groupBy={(option) => option.type}
        defaultValue={defaultOptions.territory}
        noOptionsText={t('map_noTerritoriesFound')}
        popupIcon={<SearchIcon />}
        onClose={() => {
          if (textValue !== values.territory.name)
            setTextValue(values.territory.name);
        }}
        renderGroup={(params, index) => [
          <ListSubheader key={`${params.group}_${index}`} component="div">
            {t(`territoryType_${params.group}`)}
          </ListSubheader>,
          params.children,
        ]}
        onChange={(event, newSelection) => {
          if (newSelection) setters.setTerritory(newSelection);
        }}
        value={values.territory}
        inputValue={textValue}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length < 50) setTextValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('map_territoriesAutocompleteSearch')}
            size="small"
            variant="outlined"
            fullWidth
            className={classes.autocompleteInput}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={`${part.text}_${index}`}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                  className={classes.partText}
                >
                  {part.text}
                </span>
              ))}
              {/* The stateAbbrev appears to avoid confusion with duplied option names */}
              <span className={classes.itemsBorder}>
                {option.stateAbbrev && ` - ${option.stateAbbrev}`}
              </span>
            </div>
          );
        }}
      />
    ),
    [
      t,
      options.territories,
      values.territory,
      textValue,
      loading,
      classes,
      theme,
    ]
  );
}

export default TerritoriesAutocomplete;
