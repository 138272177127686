import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  container: {
    height: 38,
    padding: '0px 18px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: -1,
      left: 0,
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      border: `solid 1px ${theme.colorText}`,
      borderRadius: 20,
    },
    '&:hover': {
      '&:before': {
        borderColor: ({ secondColor }) => secondColor,
      },
      '&:active': {
        '&:before': {
          borderColor: ({ firstColor }) => firstColor,
          borderWidth: 2,
          height: 'calc(100% - 2px)',
          width: 'calc(100% - 2px)',
        },
        '& $label': {
          color: ({ firstColor }) => firstColor,
        },
      },
    },
  },
  label: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    color: theme.colorText,
    fontSize: '1rem',
    transform: 'translate(14px, -10px) scale(0.75)',
    transformOrigin: 'top left',
    transition:
      'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

    '&:before': {
      content: "''",
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      height: 10,
      width: '100%',
      backgroundColor: theme.colorBackground,
      padding: 6,
      transform: 'translateX(-7px)',
    },
  },
  slider: {
    position: 'relative',
    zIndex: 2,

    '&.MuiSlider-root': {
      color: ({ firstColor }) => firstColor,
    },
    '&.MuiSlider-rail': {
      height: 10,
    },
  },
  sliderTrack: {
    '&.MuiSlider-track': {
      height: 10,
      borderRadius: 50,
      transform: 'translateY( -4px)',
    },
  },
  sliderRail: {
    '&.MuiSlider-rail': {
      height: 10,
      color: theme.colorSecondaryBackground,
      opacity: 1,
      borderRadius: 50,
      transform: 'translateY(-4px)',
      width: '100%',
    },
  },
  sliderThumb: {
    '&.MuiSlider-thumb': {
      backgroundColor: theme.colorBackground,
      boxShadow: ({ firstColor }) => `0px 0px 0px 1px ${firstColor}60`,
      width: 20,
      height: 20,
      transform: 'translate(-4px, -4px)',

      '&:focus': {
        boxShadow: ({ firstColor }) => `0px 0px 0px 2px ${firstColor}90`,
      },
      '&:hover': {
        boxShadow: ({ firstColor }) => `0px 0px 0px 2px ${firstColor}90`,

        '&.MuiSlider-thumb.MuiSlider-active': {
          boxShadow: ({ firstColor }) => `0px 0px 0px 4px ${firstColor}40`,
        },
      },
    },
    '&.MuiSlider-thumb.MuiSlider-active': {
      boxShadow: `0px 0px 0px 4px ${theme.colorPrimary}40`,
    },
    '&:after': {
      content: "''",
      width: 8,
      height: 8,
      borderRadius: 50,
      backgroundColor: theme.colorText,
      position: 'absolute',
      transform: 'translate(21px, 21px)',
    },
  },
}));

export default useStyles;
