import React from 'react';
import { useTranslation } from 'react-i18next';
import Document from '../../../components/document';
import useStyles from './styles';
import article1Img from '../../../assets/images/publications/article1.jpeg';

/**
 * This page shows our publications.
 */
export default function MethodPublications() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Document>
      <Document.Title>{t('publicationsMethod:title_0')}</Document.Title>
      <Document.SectionTitle>
        {t('publicationsMethod:title_1')}
      </Document.SectionTitle>
      <Document.Paragraph>
        {t('publicationsMethod:paragraph_0')}
      </Document.Paragraph>
      <Document.SectionTitle>
        {t('publicationsMethod:title_2')}
      </Document.SectionTitle>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.sciencedirect.com/science/article/pii/S0167278920304942"
        className={classes.articleWrapper}
      >
        <img className={classes.article1} src={article1Img} alt="bg3" />
      </a>
      <Document.Paragraph>
        {t('publicationsMethod:paragraph_1')}
      </Document.Paragraph>
    </Document>
  );
}
