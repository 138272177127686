import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import useFetch from '../hooks/fetch';
import {
  casesDataTypes as casesDataTypesOptions,
  projectionsDataTypes as projectionsDataTypesOptions,
  vaccinationDataTypes as vaccinationDataTypeOptions,
  defaultOptions,
  scenarioOptions,
} from '../constants/general';
import api from '../services/api';
import useScales from '../hooks/scales';
import useDate from '../hooks/date';
import useTerritoryHistory from '../hooks/territory-history';
import useProjectionsDate from '../hooks/projectionsDate';

const FiltersContext = createContext({});

/**
 * The FiltersProvider is context to provide options to filters.
 * */
export function FiltersProvider({ children }) {
  FiltersProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  /**
   * Options list:
   */
  const [territoriesOptions, setTerritoriesOptions] = useState([]);
  const { data: scalesData } = useFetch('/api/scales');
  const { data: datesOptions } = useFetch('/api/dates');
  const { data: projectionsDatesOptions } = useFetch('/api/projections/dates');
  const { data: vaccinationDatesOptions } = useFetch('/api/vaccination/dates');

  /**
   * Values list:
   */
  const [territory, setTerritory] = useState(defaultOptions.territory);
  const [casesDataType, setCasesDataType] = useState(
    defaultOptions.casesDataType
  );
  const [projectionsDataType, setProjectionsDataType] = useState(
    defaultOptions.projectionsDataType
  );

  const [vaccinationDataType, setVaccinationDataType] = useState(
    defaultOptions.vaccinationDataType
  );
  const [scenario, setScenario] = useState(defaultOptions.scenario);
  const [date, setDate] = useDate(datesOptions);
  const [projectionsDate, setProjectionsDate] = useProjectionsDate(
    projectionsDatesOptions
  );
  const [vaccinationDate, setVaccinationDate] = useDate(
    vaccinationDatesOptions
  );

  const [scale, setScale, scalesOptions] = useScales(
    scalesData ? scalesData.scales : undefined,
    territory
  );
  const [shape, setShape] = useState(null);
  const [biggestValue, setBiggestValue] = useState(1);

  /**
   * This useEffect adds territory types translations.
   */
  useEffect(() => {
    api.get('/api/translations/territories').then(({ data }) => {
      Object.keys(data).forEach((keys) => {
        i18next.addResource(
          'pt',
          'translation',
          `territoryType_${keys}`,
          data[keys].pt
        );
        i18next.addResource(
          'en',
          'translation',
          `territoryType_${keys}`,
          data[keys].en
        );
        i18next.addResource(
          'es',
          'translation',
          `territoryType_${keys}`,
          data[keys].es
        );
      });
    });
  }, []);

  return (
    <FiltersContext.Provider
      value={{
        options: {
          territories: territoriesOptions,
          scales: scalesOptions,
          casesDataTypes: casesDataTypesOptions,
          projectionsDataTypes: projectionsDataTypesOptions,
          vaccinationDataTypes: vaccinationDataTypeOptions,
          dates: datesOptions,
          projectionsDates: projectionsDatesOptions,
          vaccinationDates: vaccinationDatesOptions,
          scenarios: scenarioOptions,
        },
        values: {
          territory,
          scale,
          casesDataType,
          projectionsDataType,
          vaccinationDataType,
          scenario,
          date,
          projectionsDate,
          vaccinationDate,
          shape,
          territoryHistory: useTerritoryHistory(
            scalesData ? scalesData.hierarchy : undefined,
            territory,
            scale,
            shape,
            biggestValue
          ),
          biggestValue,
        },
        setters: {
          setTerritory,
          setScale,
          setCasesDataType,
          setProjectionsDataType,
          setVaccinationDataType,
          setScenario,
          setTerritoriesOptions,
          setDate,
          setProjectionsDate,
          setVaccinationDate,
          setShape,
          setBiggestValue,
        },
        loads: {
          options: scalesOptions && datesOptions,
        },
        projectionsLoads: {
          options: scalesOptions && projectionsDatesOptions,
        },
        vaccinationLoads: {
          options: scalesOptions && vaccinationDatesOptions,
        },
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export default FiltersContext;
