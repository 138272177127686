/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import MapGl, {
  ScaleControl,
  WebMercatorViewport,
  FlyToInterpolator,
} from 'react-map-gl';
import PropTypes from 'prop-types';
import bbox from '@turf/bbox';
import { easeCubic } from 'd3-ease';
import { useTheme } from 'react-jss';
import { useMediaQuery } from '@material-ui/core';
import { lightTheme } from '../../constants/themes';
import ThemeContext from '../../context/theming';
import MappingContext from '../../context/mapping';
import FiltersContext from '../../context/filtering';
import geodatinLargeLogo from '../../assets/images/geodatin-map-logo.png';
import geodatinSmallLogo from '../../assets/images/geodatin-map-logo-small.png';
import useStyles from './styles';

/**
 * Provides ReactMapGL component to render Mapbox map.
 */
export default function MapView({ children, navigationChildren }) {
  MapView.propTypes = {
    children: PropTypes.shape().isRequired,
    navigationChildren: PropTypes.shape().isRequired,
  };

  const { selectedTheme } = useContext(ThemeContext);
  const { viewport, setViewport, popup, onHover, onClick } = useContext(
    MappingContext
  );
  const { values } = useContext(FiltersContext);
  const [animation, setAnimation] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [cursor, setCursor] = useState('pointer');
  const [drag, setDrag] = useState(false);
  const smallCredits = useMediaQuery(
    '(min-width: 599px) and (max-width: 670px), (max-width: 350px)'
  );
  const screenBreak = useMediaQuery('(max-width: 599px)');

  /**
   * This function animates the map
   */
  useEffect(() => {
    let isSubscribed = true;

    if (values.shape && viewport) {
      // calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(values.shape);

      // construct a viewport instance from the current state
      const vp = new WebMercatorViewport(viewport);

      const { longitude, latitude, zoom } = vp.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: screenBreak ? 50 : 100,
        }
      );

      if (isSubscribed && !animation) {
        setViewport({
          ...viewport,
          longitude,
          latitude,
          zoom,
          transitionInterpolator: new FlyToInterpolator(),
          transitionDuration: 1000,
          transitionEasing: easeCubic,
        });
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [values.shape]);

  /**
   * This function change the viewport zoom value.
   * @param {Increment or decrement value} change
   */
  function changeZoom(change) {
    setViewport((oldViewport) => ({
      ...oldViewport,
      zoom:
        oldViewport.zoom + change <= 20 && oldViewport.zoom + change >= 0
          ? oldViewport.zoom + change
          : oldViewport.zoom,
    }));
  }

  function onHandleHover(event) {
    const { features } = event;
    const feature = features && features.find((f) => f.layer.id === 'data');

    if (drag) {
      setCursor('grabbing');
    } else if (feature) {
      setCursor('pointer');
    } else {
      setCursor('grab');
    }
  }

  return useMemo(
    () => (
      <div className={classes.container}>
        <MapGl
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={(vp) => setViewport(vp)}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          onHover={(e) => {
            onHandleHover(e);
            if (onHover) onHover(e);
          }}
          onClick={onClick}
          mapStyle={
            selectedTheme === lightTheme
              ? 'mapbox://styles/geodatin/ckk2zhnb13xsn17p4o86rb5om'
              : 'mapbox://styles/geodatin/ckkpr0hqw0cma17okig82juha'
          }
          dragRotate={false}
          onTransitionStart={() => setAnimation(true)}
          onTransitionEnd={() => setAnimation(false)}
          getCursor={() => cursor}
          onMouseDown={() => setDrag(true)}
          onMouseUp={() => setDrag(false)}
        >
          <ScaleControl
            maxWidth={200}
            unit="metric"
            style={{
              position: 'absolute',
              right: 10,
              bottom: 30,
            }}
            className={classes.scale}
          />

          {children}
          {popup}
        </MapGl>
        <div className={classes.navigationContainer}>
          <div className={classes.north}>
            <span className={classes.northIcon}>^</span>N
          </div>
          <div className={classes.zoomContainer}>
            <div
              role="button"
              className={classes.zoomButtom}
              onClick={() => changeZoom(1)}
              onKeyDown={() => changeZoom(1)}
              tabIndex={0}
            >
              +
            </div>
            <div
              role="button"
              className={classes.zoomButtom}
              onClick={() => changeZoom(-1)}
              onKeyDown={() => changeZoom(-1)}
              tabIndex={-1}
            >
              -
            </div>
          </div>
          {navigationChildren}
        </div>
        <div className={classes.creditsContainer}>
          <a
            href="https://geodatin.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            {smallCredits ? (
              <img
                src={geodatinSmallLogo}
                alt="geodatin_small_logo"
                style={{ height: 30 }}
                className={classes.creditLogo}
              />
            ) : (
              <img
                src={geodatinLargeLogo}
                alt="geodatin_large_logo"
                style={{ width: screenBreak ? 120 : 130 }}
                className={classes.creditLogo}
              />
            )}
          </a>
        </div>
      </div>
    ),
    [children, popup, viewport, selectedTheme, classes]
  );
}
