import { useTheme } from 'react-jss';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BarLegend from '../../../components/bar-legend';
import { vaccinationDataTypes } from '../../../constants/general';
import FiltersContext from '../../../context/filtering';

/**
 * An wrapper to build the legend to vaccination page.
 */
export default function VaccinationLegend() {
  const {
    values: { vaccinationDataType, biggestValue },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <BarLegend
      colors={[
        theme.mapVaccinationPainting.colors[8],
        theme.mapVaccinationPainting.colors[7],
        theme.mapVaccinationPainting.colors[6],
        theme.mapVaccinationPainting.colors[5],
        theme.mapVaccinationPainting.colors[4],
        theme.mapVaccinationPainting.colors[3],
        theme.mapVaccinationPainting.colors[2],
        theme.mapVaccinationPainting.colors[1],
        theme.mapVaccinationPainting.colors[0],
      ]}
      title={t('barLegend_title', {
        dataType: t(
          vaccinationDataTypes[vaccinationDataType].translation
        ).toLowerCase(),
      })}
      minValue={
        vaccinationDataType === 'vaccinatedDose1PerPopulation' ||
        vaccinationDataType === 'vaccinatedDose2PerPopulation'
          ? '0%'
          : '0'
      }
      maxValue={
        biggestValue < 0
          ? 1
          : t('general_number', {
              value:
                vaccinationDataType === 'vaccinatedDose1PerPopulation' ||
                vaccinationDataType === 'vaccinatedDose2PerPopulation'
                  ? `${biggestValue}%`
                  : biggestValue,
            })
      }
      breaks={[0, 0.2, 0.5, 0.9, 1, 2.5, 3, 4, 8]}
    />
  );
}
