import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'react-jss';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, useMediaQuery } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import useStyles from './styles';

/**
 * This component genereta a detailed legend using circles.
 * @param {A list containing 6 legend colors} colors
 * @param {A list containing 3 colors corresponding texts} texts
 * @param {The legend title} title
 */
export default function RateLegend({ colors, texts, title }) {
  RateLegend.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    texts: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
  };
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [opened, setOpened] = useState(false);
  const [coloredDiv, setColoredDiv] = useState();
  const mobileWidth = useMediaQuery('(max-width: 599px)');

  /**
   * This useEffect fills coloredDiv items.
   */
  useEffect(() => {
    if (colors && texts) {
      const sphereItems = [];

      for (let i = 0; i * 2 < colors.length; i += 1) {
        sphereItems.push(
          <div
            key={colors[i * 2]}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: (i + 1) * 2 < colors.length ? 10 : 0,
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: `linear-gradient(90deg, ${colors[i * 2]} 0%, ${
                    colors[i * 2 + 1]
                  } 100%`,
                  borderRadius: 50,
                }}
              />
            </div>
            <Typography variant="body2" style={{ marginLeft: 10 }}>
              {texts[i]}
            </Typography>
          </div>
        );
      }

      setColoredDiv(sphereItems);
    }
  }, [colors, texts]);

  /**
   * This function handle the legend opening
   */
  function handleOpen() {
    setOpened(!opened);
  }

  return (
    <div className={classes.container}>
      <Grow in={!opened}>
        <div
          className={classes.closedContainer}
          role="button"
          onClick={handleOpen}
          onKeyDown={handleOpen}
          tabIndex={-1}
        >
          <div
            style={{
              backgroundColor: colors[colors.length - 2],
              borderRadius: 10,
              width: 18,
              height: 18,
              position: 'relative',
            }}
          >
            <div
              style={{
                backgroundColor: colors[(colors.length / 3).toFixed()],
                borderRadius: 10,
                width: 13,
                height: 13,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            />
            <div
              style={{
                backgroundColor: colors[1],
                borderRadius: 10,
                width: 8,
                height: 8,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            />
          </div>
        </div>
      </Grow>
      <Grow
        style={{ transformOrigin: mobileWidth ? '100% 0 0' : '0 0 0' }}
        in={opened}
      >
        <div className={classes.openedContainer}>
          <div
            className={classes.closeButton}
            role="button"
            onClick={handleOpen}
            onKeyDown={handleOpen}
            tabIndex={-1}
          >
            <CloseIcon style={{ fontSize: 16 }} />
          </div>
          <Typography
            variant="body2"
            style={{ margin: '0px 25px' }}
            align="center"
          >
            {title}
          </Typography>
          <div className={classes.colorsContainer}>{coloredDiv}</div>
        </div>
      </Grow>
    </div>
  );
}
